import React, { Component } from 'react'
import Leaflet from 'leaflet';
import {
    Circle,
    FeatureGroup,
    LayerGroup,
    Map,
    Popup,
    Rectangle,
    TileLayer
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

Leaflet.Icon.Default.imagePath =
'../node_modules/leaflet'

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const rectangle = [
    [51.49, -0.08],
    [51.5, -0.06],
  ]

export default class LayerGroupMap extends Component {
state = {
    lat: 51.505,
    lng: -0.09,
    zoom: 13,
}


render() {
    const position = [this.state.lat, this.state.lng];
    return (
    <Map center={position} zoom={this.state.zoom} style={{height : '300px'}}>
        <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LayerGroup>
          <Circle center={position} fillColor="blue" radius={200} />
          <Circle center={position} fillColor="red" radius={100} stroke={false} />
          <LayerGroup>
            <Circle
              center={[51.51, -0.08]}
              color="green"
              fillColor="green"
              radius={100}
            />
          </LayerGroup>
        </LayerGroup>
        <FeatureGroup color="purple">
          <Popup>Popup in FeatureGroup</Popup>
          <Circle center={[51.51, -0.06]} radius={200} />
          <Rectangle bounds={rectangle} />
        </FeatureGroup>
    </Map>
    )
}
}