import { SUB_CATEGORY_DATA } from "../helpers/constants";
export const generateObject = (
  val,
  state,
  menuType,
  logoPhoto = "",
  cardPhoto = ""
) => {
  let types = [];
  menuType.forEach((element) => {
    types.push(element.value);
  });

  let sb = () => {
    let a = [];
    SUB_CATEGORY_DATA.forEach((v) => {
      a.push(val[v.subCategoryLabel] ? v.value : "");
    });
    return [...a];
  };

  let obj = {
    name: val?.storeName,
    address: {
      addressLine1: val?.AddressLine1,
      postalCode: val?.PostalCode,
      city: val?.City,
      province: val?.Province,
      country: val?.Country,
    },
    menuTypes: types,
    types: [`${val?.Types}`],
    rating: parseInt(val?.Rating),
    ratingCount: parseInt(val?.RatingCount),
    category: val?.Category,
    reviewsCount: 114,
    preparationTime: val?.PreparationTime,
    preparationTimeInMinutes: val?.PreparationTimeInMinutes,
    openingTimes: val?.OpeningTimes,
    openingTimesInReadable: val?.OpeningTimesReadable,
    additionalInfo: val?.AdditionalInfo,
    breakfast: val?.Breakfast,
    noAlchohol: val?.NoAlcohol,
    lunch: val?.Lunch,
    dinner: val?.Dinner,
    dineInAllow: val?.DineInAllow,
    vegOnly: val?.VegOnly,
    phoneNumbers: [`${val?.storePhoneNumber}`],
    emails: [`${val?.Email}`],
    cardPhoto: cardPhoto ? cardPhoto : val?.cardPhoto,
    logoPhoto: logoPhoto ? logoPhoto : val?.logoPhoto,
    menuItems: [state],
    googleMapLocation: val?.MapLocation,
    location: [val?.Location, val?.Location2],
    subCategories: sb(),
  };
  return obj;
};

export const generateState = (response) => {
  let types = [];
  response.data.menuTypes.forEach((element) => {
    let obj = {
      label: element,
      value: element,
    };
    types.push(obj);
  });
  let stateObject = {
    ...response.data,
    AddressLine1: response.data?.address?.addressLine1,
    City: response.data?.address?.city,
    Country: response.data?.address?.country,
    PostalCode: response.data?.address?.postalCode,
    Province: response.data?.address?.province,
    Email: response.data?.emails[0],
    storeName: response.data?.name,
    Types: response.data?.types?.join(),
    Rating: response.data?.rating,
    RatingCount: response.data?.ratingCount,
    PreparationTime: response.data?.preparationTime,
    PreparationTimeInMinutes: response.data?.preparationTimeInMinutes,
    OpeningTimes: response.data?.openingTimes,
    OpeningTimesReadable: response.data?.openingTimesInReadable,
    AdditionalInfo: response.data?.additionalInfo,
    Breakfast: response.data?.breakfast,
    NoAlcohol: response.data?.noAlchohol,
    Lunch: response.data?.lunch,
    Dinner: response.data?.dinner,
    DineInAllow: response.data?.dineInAllow,
    VegOnly: response.data?.vegOnly,
    storePhoneNumber: response.data?.phoneNumbers[0],
    MapLocation: response.data?.googleMapLocation,
    Location: response.data?.location[0],
    Location2: response.data?.location[1],
    Category: response.data?.category,
    cardPhoto: response.data?.cardPhoto,
    logoPhoto: response.data?.logoPhoto,
    rows: response.data?.menuItems?.sort((a, b) =>
      a.menuType > b.menuType ? 1 : -1
    ),
    menuType: types,
  };
  return stateObject;
};

export const generateMenuObject = (row) => {
  let rowObject = row;

  if (
    Object.keys(rowObject.menuType).length &&
    typeof rowObject.menuType === "object"
  ) {
    let temp = JSON.parse(JSON.stringify(rowObject));
    temp.menuType = temp.menuType.value;
    return temp;
  }

  if (
    Object.keys(rowObject.menuOptions).length &&
    typeof rowObject.menuOptions === "object"
  ) {
  }

  return rowObject;
};

export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    if (!timer) {
      func.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
};
