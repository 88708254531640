import React, { useState, Fragment, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, FormGroup, Label, Input, Alert } from "reactstrap";
import Axios from "axios";
import API from "../../config.json";

const MenuTypeNotes = ({
  menuTypes = [],
  menuTypesNotes = [],
  refresh,
  driverId,
  driverKey,
  storeId,
}) => {
  let form;
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [types, setTypes] = useState([]);

  useEffect(() => {
    if (menuTypesNotes?.length) {
      setTypes(menuTypesNotes);
    } else if (menuTypes?.length) {
      let obj = [];
      menuTypes.map((item) => {
        obj.push({ type: item.value });
      });
      setTypes(obj);
    }
  }, [menuTypesNotes, menuTypes]);

  const updateMenuTypeNotes = async (Object) => {
    return await Axios.post(
      API.DELIVERY_API +
        "addOrUpdateStoreMenuTypesInfo/" +
        driverId +
        "/" +
        driverKey +
        "/" +
        storeId,
      JSON.stringify(Object),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const handleSubmit = async (event, error, values) => {
    setLoading(true);
    if (!error.length) {
      let obj = [];
      menuTypes.map((item, idx) => {
        let key = "description" + idx;
        obj.push({ type: item.value, description: values[key] });
      });
      let sendObject = { menuTypesNotes: obj };
      const response = await updateMenuTypeNotes(sendObject).catch((error) => {
        console.error(error);
        setErrorMsg(error.message);
        setLoading(false);
      });
      if (response?.data) {
        refresh();
        setLoading(false);
      }
    }
  };

  return (
    <AvForm
      onSubmit={handleSubmit}
      id="menutypenotes"
      className="needs-validation"
      ref={(c) => (form = c)}
    >
      <Row>
        {types.length &&
          types.map((item, idx) => (
            <Fragment key={idx}>
              <Col md="6">
                <FormGroup>
                  <Label>Type</Label>
                  <Input
                    name={"type" + idx}
                    value={item.type}
                    disabled
                    className="form-control"
                    style={{ background: "#dddddd" }}
                    type="text"
                    id={"type" + idx}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label htmlFor={"description" + idx}>Description</Label>
                  <AvField
                    name={"description" + idx}
                    placeholder="Description"
                    type="text"
                    errorMessage="Enter Description"
                    className="form-control"
                    validate={{
                      required: { value: true },
                    }}
                    id={"description" + idx}
                    value={item.description}
                  />
                </FormGroup>
              </Col>
            </Fragment>
          ))}
        {errorMsg && <Alert color="danger">{errorMsg}</Alert>}
        <button
          type="submit"
          className="btn btn-primary waves-effect waves-light"
          // onClick={handleSubmit}
          disabled={loading}
        >
          {loading && (
            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
          )}
          Update Menu or Product Type Description
        </button>
      </Row>
    </AvForm>
  );
};

export default MenuTypeNotes;
