import React from "react";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import { Row, Col, FormGroup, Label } from "reactstrap";
import FileUpload from "./FileUpload";
import CreatableSelect from "react-select/creatable";
import { SUB_CATEGORY_DATA } from "../../helpers/constants";

const AddStoreForm = ({ state, handleSubmit, setFile, updateState }) => {
  var form;
  const components = {
    DropdownIndicator: null,
  };
  const handleSelectChange = (values) => {
    console.log(values);
    updateState({ menuType: values });
  };
  const handleInputChange = (inputValue) => {
    updateState({ inputValue });
  };
  const handleKeyDown = (event) => {
    const { inputValue, menuType = [] } = state;
    let oldVal = menuType
      ? [
        ...menuType,
        {
          label: inputValue,
          value: inputValue,
        },
      ]
      : [
        {
          label: inputValue,
          value: inputValue,
        },
      ];
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        updateState({
          menuType: oldVal,
        });
        event.preventDefault();
        break;
      default:
        console.log("nothing");
    }
  };

  return (
    <AvForm
      onSubmit={handleSubmit}
      id="editDriverForm"
      className="needs-validation"
      ref={(c) => (form = c)}
    >
      <Row>
        <Col md="12">
          <FormGroup>
            <Label htmlFor="storeName">{state.id}</Label>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="storeName">Store Name</Label>
            <AvField
              name="storeName"
              placeholder="Store Name"
              type="text"
              errorMessage="Enter Store Name"
              className="form-control"
              validate={{
                required: { value: true },
              }}
              id="storeName"
              value={state.storeName}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="storePhoneNumber">Store phone number</Label>
            <AvField
              name="storePhoneNumber"
              placeholder="Store phone number"
              type="text"
              errorMessage="Enter Store phone number"
              className="form-control"
              validate={{
                required: { value: true },
              }}
              id="storePhoneNumber"
              value={state.storePhoneNumber}
            />
          </FormGroup>
        </Col>
        {state.AddressLine1 && (
          <Col md="6">
            <FormGroup>
              <Label htmlFor="toAddressLine1">Address</Label>
              <br />
              <Label htmlFor="toAddressLine1">{state.AddressLine1}</Label>
              <br />
              <Label htmlFor="toAddressLine1">{state.City}</Label>,&nbsp;
              <Label htmlFor="toAddressLine1">{state.Province}</Label>
              <br />
              <Label htmlFor="toAddressLine1">{state.PostalCode}</Label>
              <br />
              <Label htmlFor="toAddressLine1">{state.Country}</Label>
            </FormGroup>
          </Col>
        )}
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <AvField
              type="select"
              name="Category"
              label="Category"
              errorMessage="Enter Category"
              className="form-control"
              validate={{ required: { value: true } }}
              id="Category"
              value={state.Category}
            >
              <option>Select</option>
              <option>Restaurant</option>
              <option>Flower</option>
              <option>Alcohol</option>
              <option>Retail</option>
            </AvField>
          </FormGroup>
        </Col>
        
      
        <Col md="2">
          <FormGroup>
            <Label htmlFor="Rating">Rating</Label>
            <AvField
              name="Rating"
              placeholder="e.g 4"
              type="text"
              errorMessage="Enter Rating"
              className="form-control"
              validate={{
                required: { value: true },
                pattern: {
                  value: "/([0-9])/g",
                },
              }}
              id="Rating"
              value={state.Rating}
            />
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <Label htmlFor="RatingCount">Rating Count</Label>
            <AvField
              name="RatingCount"
              placeholder="e.g 87"
              type="text"
              errorMessage="Enter Rating Count"
              className="form-control"
              validate={{
                required: { value: true },
                pattern: {
                  value: "/([0-9])/g",
                },
              }}
              id="RatingCount"
              value={state.RatingCount}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="2">
          <FormGroup>
            <Label htmlFor="PreparationTime">Preparation Time</Label>
            <AvField
              type="text"
              name="PreparationTime"
              id="PreparationTime"
              className="form-controls"
              placeholder="e.g 30 mins"
              errorMessage="Enter Preparation Time"
              validate={{ required: { value: true } }}
              value={state.PreparationTime}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label htmlFor="PreparationTimeInMinutes">Preparation Time in Minutes</Label>
            <AvField
              name="PreparationTimeInMinutes"
              placeholder="e.g 30 (only number)"
              type="text"
              errorMessage="Enter  Preparation Time in Minutes"
              className="form-control"
              validate={{
                required: { value: true },
                pattern: {
                  value: "/([0-9])/g",
                },
              }}
              id="PreparationTimeInMinutes"
              value={state.PreparationTimeInMinutes}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="OpeningTimes">Opening Time (Mon - Sun)</Label>
            <br />
            <Label htmlFor="OpeningTimesReadable">
              e.g 0600#2300, 2400#2400, 0600#2300, 0600#2300, 0600#2300,
              0600#2330, 0600#2330
            </Label>
            <AvField
              name="OpeningTimes"
              placeholder=""
              type="text"
              errorMessage="Enter  Opening Time"
              className="form-control"
              validate={{ required: { value: true } }}
              id="OpeningTimes"
              value={state.OpeningTimes}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="OpeningTimesReadable">
              Opening Times Readable (Mon - Sun)
            </Label>
            <Label htmlFor="OpeningTimesReadable">
              e.g 6:00 AM - 11:00 PM, We are closed, 6:00 AM - 11:00 PM, 6:00 AM
              - 11:00 PM, 6:00 AM - 11:00 PM, 6:00 AM - 11:30 PM, 6:00 AM -
              11:30 PM
            </Label>
            <AvField
              name="OpeningTimesReadable"
              placeholder=""
              type="text"
              errorMessage="Enter  Opening Times Readable"
              className="form-control"
              validate={{
                required: { value: true },
                pattern: {
                  value: "/([0-9])/g",
                },
              }}
              id="OpeningTimesReadable"
              value={state.OpeningTimesReadable}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="AdditionalInfo">Additional Info</Label>
            <AvField
              name="AdditionalInfo"
              placeholder="e.g A Fascinating Flavour Experience."
              type="text"
              errorMessage="Enter  Additional Info "
              className="form-control"
              validate={{ required: { value: true } }}
              id="AdditionalInfo"
              value={state.AdditionalInfo}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="Email">Email</Label>
            <AvField
              name="Email"
              placeholder="Email "
              type="email"
              errorMessage="Enter  Email "
              className="form-control"
              validate={{ required: { value: true } }}
              id="Email"
              value={state.Email}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="MapLocation">Map Location</Label>
            <AvField
              name="MapLocation"
              placeholder="e.g https://maps.google.com/maps?q=Hyderabadi%20Bawarchi,%20670%20First%20St,%20London,%20ON%20N5V%202A3&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
              type="text"
              errorMessage="Enter  Map Location "
              className="form-control"
              validate={{ required: { value: true } }}
              id="MapLocation"
              value={state.MapLocation}
            />
          </FormGroup>
        </Col>
        {state.Location && (
          <Col md="3">
            <FormGroup>
              <Label htmlFor="Location">Location</Label>
              <br />
              <Label htmlFor="Location">{state.Location}</Label>,{" "}
              <Label htmlFor="Location">{state.Location2}</Label>
            </FormGroup>
          </Col>
        )}
      </Row>
      <Row>
        <Col md="8">
          {state.logoPhoto && (
            <img src={state.logoPhoto} alt="" className="avatar-md mr-4 mb-4" />
          )}
          {!state.logoPhoto && "No logo image found"}
        </Col>
        <Col md="4">
          <FileUpload
            file={state.file}
            setFile={setFile}
            name="logoPhotoFile"
            title="Logo Photo"
            required={state.storeInfoFound ? false : true}
          />
        </Col>
        <Col md="8">
          {state.cardPhoto && (
            <img src={state.cardPhoto+"?tr=w-0.8,h-0.8"} className="mr-4 mb-4"alt="" />
          )}
          {!state.cardPhoto && "No card image found"}
        </Col>
        <Col md="4">
          <FileUpload
            file={state.file}
            setFile={setFile}
            name="cardPhotoFile"
            title="Card Photo"
            required={state.storeInfoFound ? false : true}
          />
        </Col>
        <Col md="12">
          <FormGroup>
            <Label htmlFor="MenuType">
              Menu or Product Type (e.g Appetizers, Drinks)
            </Label>
            <CreatableSelect
              isMulti
              value={state.menuType}
              onChange={handleSelectChange}
              options={state.menuType}
              onInputChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        {state.Category == 'Restaurant' &&
          <Col md="12 pb-4">
            <Label htmlFor="MenuType">Subcategories</Label>

            {SUB_CATEGORY_DATA.map((data, idx) => {
              return (
                <AvGroup check key={idx}>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name={data.subCategoryLabel}
                      trueValue={data.value}
                      falseValue={""}
                      checked={state?.subCategories?.includes(data.value)}
                    />
                    {data.value}
                  </Label>
                </AvGroup>
              );
            })}
          </Col>
        }
        <Col md="12 pb-4">
          <Label htmlFor="MenuType">Other Options</Label>
          <AvGroup check>
            <Label check>
              <AvInput
                type="checkbox"
                name="Breakfast"
                trueValue={true}
                falseValue={false}
                checked={state.Breakfast}
              />
              Breakfast
            </Label>
          </AvGroup>
          <AvGroup check>
            <Label check>
              <AvInput
                type="checkbox"
                name="NoAlcohol"
                trueValue={true}
                falseValue={false}
                checked={state.NoAlcohol}
              />
              No Alcohol
            </Label>
          </AvGroup>
          <AvGroup check>
            <Label check>
              <AvInput
                type="checkbox"
                name="Lunch"
                trueValue={true}
                falseValue={false}
                checked={state.Lunch}
              />
              Lunch
            </Label>
          </AvGroup>
          <AvGroup check>
            <Label check>
              <AvInput
                type="checkbox"
                name="DineInAllow"
                trueValue={true}
                falseValue={false}
                checked={state.DineInAllow}
              />
              Dine In Allowed
            </Label>
          </AvGroup>
          <AvGroup check>
            <Label check>
              <AvInput
                type="checkbox"
                name="VegOnly"
                trueValue={true}
                falseValue={false}
                checked={state.VegOnly}
              />
              Veg Only
            </Label>
          </AvGroup>
          <AvGroup check>
            <Label check>
              <AvInput
                type="checkbox"
                name="Dinner"
                trueValue={true}
                falseValue={false}
                checked={state.Dinner}
              />
              Dinner
            </Label>
          </AvGroup>
        </Col>
      </Row>

      <button
        type="submit"
        className="btn btn-primary waves-effect waves-light"
        disabled={state.loading}
      >
        {state.loading && (
          <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
        )}
        Add / Update Store info
      </button>
    </AvForm>
  );
};

export default AddStoreForm;
