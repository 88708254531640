import React, { Component } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";

import config from "../../config.json";

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      errorMsg: "",
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  // handleValidSubmit
  handleValidSubmit(event, values) {
    console.log("values", values);
    if (values) {
      fetch(config.DELIVERY_API + "forgotPassword/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            this.setState({ errorMsg: data.message || data.error });
          } else {
            this.setState({ errorMsg: "" });
            this.toggle();
            // this.form && this.form.reset();
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          this.setState({ errorMsg: error });
        });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2"></i>
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-soft-primary">
                    <Row>
                      <Col className="col-7">
                        <div className="text-dark p-4">
                          <h5 className="text-dark">Welcome Back !</h5>
                          <p>
                            Sign in to continue to Delivery Deals Admin / Store
                            app.
                          </p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {this.state.errorMsg ? (
                        <Alert color="danger">{this.state.errorMsg}</Alert>
                      ) : (
                        ""
                      )}
                      {this.props.forgetError && this.props.forgetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {this.props.forgetError}
                        </Alert>
                      ) : null}
                      {this.props.forgetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {this.props.forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <AvForm
                        className="form-horizontal mt-4"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        <div className="form-group">
                          <AvField
                            name="phoneNumber"
                            label="Phone Number"
                            className="form-control"
                            placeholder="Enter Phone Number"
                            type="tel"
                            required
                          />
                        </div>
                        <Row className="form-group">
                          <Col className="text-right">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Reset
                            </button>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Go back to{" "}
                    <Link
                      to="login"
                      className="font-weight-medium text-primary"
                    >
                      Login
                    </Link>{" "}
                  </p>
                  <p>
                    © {new Date().getFullYear()} Delivery Deals. Crafted with{" "}
                    <i className="mdi mdi-heart text-danger"></i> by TurboDevelop
                  </p>
                </div>
              </Col>
            </Row>

            <Modal isOpen={this.state.modal} toggle={this.toggle}>
              <ModalHeader toggle={this.toggle}>Successs</ModalHeader>
              <ModalBody>You will get response in your phone.</ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  tag={Link}
                  to={{
                    pathname: "/login",
                  }}
                  onClick={this.toggle}
                >
                  OK
                </Button>{" "}
                {/* <Button color="secondary" onClick={this.toggle}>Cancel</Button> */}
              </ModalFooter>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
);
