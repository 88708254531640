import React, { useEffect, useState, useCallback, Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoBox,
  useJsApiLoader,
} from "@react-google-maps/api";

const MapReport = (props) => {
  const { lookType = 'version2', mapCenter, dropOffMapCoordinates = [], removedStores = [] } = props;
  
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBfXMcqxKYOgZAo9L1b7NHGvE_IQMWJ--Q",
  });
  const containerStyle1 = {
    width: "100%",
    height: "calc(45vh)",
  };

  const containerStyle2 = {
    width: "100%",
    height: "calc(72vh)",
  };

  const options = { closeBoxURL: "", enableEventPropagation: true };

  const onLoad = useCallback(function onLoad(mapInstance) {
    // do something with map Instance
  });

  const renderMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={lookType == 'version2' ? containerStyle2 : containerStyle1}
        center={mapCenter}
        zoom={12} clickableIcons={false}
        options={{ styles: [{ elementType: "labels", featureType: "poi", stylers: [{ visibility: "off", }], }], }}
      >
        {dropOffMapCoordinates.map((order, key) => {
          return (
            <>
              {!removedStores?.includes(order.store) ? 
                <Fragment key={"_map_" + key}>
                  <InfoBox
                    onLoad={onLoad}
                    options={options}
                    position={{
                      lat: order?.dropOffLocation[0],
                      lng: order?.dropOffLocation[1],
                    }}
                    zIndex={9}
                  >
                    <div
                      style={{
                        padding: 2,
                        width: '200px',
                        display: "flex",
                        overflowY: 'hidden',
                        overflowX: 'hidden'
                      }}
                    >
                      <div
                        style={{
                          margin: 0,
                          color: "#F44336",
                          overflowY: 'hidden',
                          overflowX: 'hidden'
                        }}
                      >
                        <i className="mdi mdi-map-marker font-size-24 align-middle"></i>
                        <span style={{
                            padding:0,
                            fontSize: 8, fontWeight: 'bold',
                            whiteSpace: "nowrap",
                            overflowY: 'hidden',
                            overflowX: 'hidden', color: "#000",
                            opacity: 0.8
                          }}>{order.store ? order.store?.substring(0,7)?.toUpperCase() : "NA"}</span>
                      </div>
                    </div>
                  </InfoBox>
                </Fragment> : <></>}</>
          );
        })}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? (
    renderMap()
  ) : (
    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
  );
};

export default MapReport;
