import React, { Component } from 'react';
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Form, FormGroup, Label, Button, Alert, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import config from "../../config.json";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class EditStore extends Component {
    constructor() {
        super();
        this.state = {
            modal: false,
            errorMsg: '',
            storeId: '',
            userRole: '',
            enablePassword: true,
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    getStoreDetails = (id) => {
        let driverKey = sessionStorage.getItem("secretKey");
        let driverId = sessionStorage.getItem("userId");
        fetch(config.DELIVERY_API + "/findStore/" + driverId + "/" + driverKey + "/" + id)
            .then(response => response.json())
            .then(data => {
                var dataRes = data;
                data.addressLine1 = dataRes.address.addressLine1;
                data.city = dataRes.address.city;
                data.province = dataRes.address.province;
                data.additional = dataRes.address.province;
                data.postalCode = dataRes.address.postalCode;
                data.additionalInfo = dataRes.storeNotes;
                data.cityWideFeeExists = dataRes.cityWideFeeExists;
                data.cityWideFee = dataRes.cityWideFee;
                data.password = dataRes.password;
                this.setState(
                    data
                );
            });
    }


    componentDidMount() {
        const { match, location, history } = this.props;
        console.log(location)

        if (location.state && location.state.id) {
            this.getStoreDetails(location.state.id)
            var userRoleInSession = sessionStorage.getItem("userRole");
            
            this.setState({
                id: location.state.id,
                userRole: userRoleInSession,
            })
        }
    }

    enablePassword = () => {
        this.setState({
            enablePassword: !this.state.enablePassword
        });
    }

    handleSubmit = (event, error, values) => {
        this.setState({ error, values });


        if (typeof error?.length !== "undefined" && error?.length === 0) {

            if (values) {
                try {
                    let driverKey = sessionStorage.getItem("secretKey");
                    let driverId = sessionStorage.getItem("userId");
                    values.role = 'DRIVER'
                    this.updateStore(values, driverId, driverKey);
                } catch (error) {
                    console.log(error);
                }
            } else {
                console.log('values is null, update Driver error');
                this.setState({ errorMsg: 'values is null, update Driver error' });
            }
        }

    }

    updateStore = async (Object, driverId, driverKey) => {
        fetch(config.DELIVERY_API + "updateStore/" + driverId + "/" + driverKey + "/" + this.state.id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(Object),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    this.setState({ errorMsg: data.error });
                } else {
                    this.setState({ errorMsg: "" });
                    this.toggle();
                    // this.form && this.form.reset();
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                this.setState({ errorMsg: error });
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Stores" breadcrumbItem="Edit Store" />

                        {this.state.errorMsg ? <Alert color="danger">{this.state.errorMsg}</Alert> : ''}

                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <AvForm onSubmit={this.handleSubmit} id="editDriverForm" className="needs-validation" >
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="firstName">Store owner's first name*</Label>
                                                        <AvField
                                                            name="firstName"
                                                            placeholder="First name"
                                                            type="text"
                                                            errorMessage="Enter first name"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="firstName"
                                                            value={this.state.firstName}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="lastName">Store owner's last name*</Label>
                                                        <AvField
                                                            name="lastName"
                                                            placeholder="Last name"
                                                            type="text"
                                                            errorMessage="Enter last name"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="lastName"
                                                            value={this.state.lastName}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="companyName">Store name*</Label>
                                                        <AvField
                                                            name="companyName"
                                                            placeholder="Store name"
                                                            type="text"
                                                            errorMessage="Enter store name"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="companyName"
                                                            value={this.state.companyName}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="email">Email</Label>
                                                        <AvField
                                                            name="email"
                                                            placeholder="Email"
                                                            type="email"
                                                            errorMessage="Please provide a valid email id."
                                                            className="form-control"
                                                            validate={{ required: { value: false } }}
                                                            id="email"
                                                            value={this.state.email}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="addressLine1">Address line*</Label>
                                                        <AvField
                                                            name="addressLine1"
                                                            placeholder="Address Line"
                                                            type="text"
                                                            errorMessage="Enter Address Line"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="addressLine1"
                                                            value={this.state.addressLine1}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="addressLine2">Apt / Unit number</Label>
                                                        <AvField
                                                        name="addressLine2"
                                                        placeholder="Apt / Unit number"
                                                        type="text"
                                                        errorMessage="Enter Apt / Unit number"
                                                        className="form-control"
                                                        validate={{ required: { value: false } }}
                                                        id="addressLine2"
                                                        value={this.state.addressLine2}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md="4">
                                                
                                                    <FormGroup>
                                                        <Label htmlFor="city">City*</Label>
                                                        <AvField
                                                            name="city"
                                                            placeholder="City"
                                                            type="text"
                                                            errorMessage="Enter city"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="city"
                                                            value={this.state.city}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">

                                                    <FormGroup>
                                                        <Label htmlFor="province">Province</Label>
                                                        <AvField
                                                            name="province"
                                                            placeholder="Province"
                                                            type="text"
                                                            errorMessage="Enter province"
                                                            className="form-control"
                                                            validate={{ required: { value: false } }}
                                                            id="province"
                                                            value={this.state.province}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                               
                                                    <FormGroup>
                                                        <Label htmlFor="postalCode">Postal Code*</Label>
                                                        <AvField
                                                            name="postalCode"
                                                            placeholder="Postal Code"
                                                            type="text"
                                                            errorMessage="Enter Address Line"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="postalCode"
                                                            value={this.state.postalCode}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>

                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="additionalInfo">Pick up instructions</Label>
                                                        <AvField
                                                        name="additionalInfo"
                                                        placeholder="e.g While pick up can you park in the back side parking lot"
                                                        type="text"
                                                        errorMessage="Enter pick up instructions"
                                                        className="form-control"
                                                        validate={{ required: { value: false } }}
                                                        id="additionalInfo"
                                                        value={this.state.additionalInfo}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="1"></Col>
                                                <Col md="2">
                                                    <FormGroup>
                                                        <Label check>
                                                            <AvInput
                                                                type="checkbox"
                                                                name="cityWideFeeExists"
                                                                trueValue={true}
                                                                falseValue={false}
                                                                checked={this.state.cityWideFeeExists}
                                                                value={this.state.cityWideFeeExists}
                                                                />
                                                                {"City wide fee"}
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="3">
                                            
                                                    <FormGroup>
                                                        <Label htmlFor="cityWideFee">City wide fee (e.g 12.0)</Label>
                                                        <AvField
                                                            name="cityWideFee"
                                                            placeholder="10"
                                                            type="number"
                                                            errorMessage="Enter city wide fee"
                                                            className="form-control"
                                                            validate={{ required: { value: false } }}
                                                            id="cityWideFee"
                                                            value={this.state.cityWideFee}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="password">Store password ( On entered will override the password )</Label>
                                                        <AvField
                                                            name="password"
                                                            placeholder="Password"
                                                            type="text"
                                                            errorMessage="Enter password"
                                                            className="form-control"
                                                            validate={{ required: { value: false } }}
                                                            id="password" disabled={this.state.enablePassword}
                                                            value={this.state.password}
                                                            
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="2">
                                                    <FormGroup>
                                                        <Label htmlFor="passwordBtn">{" "}</Label>
                                                        <Button style={{marginTop: '25px'}} color="primary" type="button" onClick={this.enablePassword}>Enable password feild</Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="8">
                                                    <FormGroup>
                                                        <Label htmlFor="submitBtn">{" "}</Label>
                                                        <Button style={{marginTop: '25px'}} color="primary" type="submit" onClick={this.handleSubmit}>Update store</Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <div>
                            <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-dialog modal-dialog-centered">
                                <ModalHeader toggle={this.toggle}>Successs</ModalHeader>
                                <ModalBody>
                                    Updated store info successfully
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" tag={Link} to={{
                                        pathname: '/store-grid',
                                    }} onClick={this.toggle}>OK</Button>{' '}
                                </ModalFooter>
                            </Modal>
                        </div>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default EditStore;