import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: ''
    };
  }

  componentDidMount() {
    var userRoleInSession = sessionStorage.getItem("userRole");
    this.setState({
      userRole: userRoleInSession
    });
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t("Menu")}</li>
            <li>
              <Link to="/#" className="waves-effect">
                {false && (
                  <>
                    <i className="bx bx-home-circle"></i>
                    <span className="badge badge-pill badge-info float-right">
                      03
                    </span>
                  </>
                )}
                <i className="bx bx-home-circle"></i>
                <span>{this.props.t("Dashboards")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/dashboard">{this.props.t("Home")}</Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">{this.props.t("Apps")}</li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-task"></i>
                <span>{this.props.t("Store Orders")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="ecommerce-orders">{this.props.t("Orders")}</Link>
                </li>

                <li>
                  <Link to="order-create">{this.props.t("Create Order")}</Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">Pages</li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-user-circle"></i>
                <span>{this.props.t("My Account")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="contacts-profile">{this.props.t("Profile")}</Link>
                </li>
                <li><Link to="Change-password">{this.props.t('Change Password')}</Link></li>
                <li>
                  <Link to="login">{this.props.t("Logout")}</Link>
                </li>
              </ul>
            </li>
            {this.state.userRole === 'SUPER_ADMIN' &&
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-user"></i>
                  <span>{this.props.t("Drivers")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="contacts-grid">{this.props.t("Drivers")}</Link>
                  </li>
                  <li>
                    <Link to="projects-create">{this.props.t("Add Driver")}</Link>
                  </li>
                </ul>
              </li>
            }
            {(this.state.userRole === 'ADMIN' || this.state.userRole === 'SUPER_ADMIN') &&
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-store"></i>
                  <span>{this.props.t("Store")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="store-grid">{this.props.t("Store Info")}</Link>
                  </li>
                  <li>
                    <Link to="store-create">{this.props.t("Add Store")}</Link>
                  </li>
                </ul>
              </li>
            }
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withNamespaces()(SidebarContent));
