import React, { Component } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle, Media, Table, CardSubtitle, FormGroup, Label, Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import mini card widgets
import Select from "react-select";

//Import Images
import profile1 from "../../assets/images/profile-img.png"

// import charts
import ApexRevenue from "./ApexRevenue";

import config from "./../../config.json"

import KeyMarvel, { imageBasePath, imageSize } from "../../helpers/keymarvel";

const optionGroup1 = [
    { label: "Ontario", value: "ON" },
    { label: "British Columbia", value: "BC" }
];

const optionGroup2 = [
    { label: "Canada", value: "Canada" }
];

class ContactsProfile extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        modal: false,
        email: null,
        firstName: null,
        lastName: null,
        profilePhoto: null,
        address: null,
        phoneNumber: null,
        country: null,
        province: null,
        errorMsg: "",
        loading: false,
        client: {},
        selectedCountry: null,
        selectedProvince: null,
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    nextPage = () => {
        this.props.history.push("ecommerce-orders");
    }

    componentDidMount = async () => {
        this.setState({
            selectedProvince: { value: 'ON', label: 'Ontario' },
            selectedCountry: { value: 'Canada', label: 'Canada' }
        });

        this.getDashboardDetails();
        await this.getAccount();
    };

    //Select
    handleSelectProvince = (selectedProvince) => {
        this.setState({ selectedProvince });
    };

    //Select
    handleSelectCountry = (selectedCountry) => {
        this.setState({ selectedCountry });
    };

    getDashboardDetails = async () => {
        let driverKey = sessionStorage.getItem("secretKey");
        let driverId = sessionStorage.getItem("userId");
        fetch(config.DELIVERY_API + "/dashboardDetails/" + driverId + "/" + driverKey + "/")
        .then(response => response.json())
        .then(data => {
            this.setState(
                data
            );

            if (data.address?.country == 'Canada') {
                this.setState({selectedCountry: { value: 'Canada', label: 'Canada' }})
            }
            if (data.address?.province == 'ON') {
                this.setState({selectedProvince: { value: 'ON', label: 'Ontario' }})
            }
            if (data.address?.province == 'BC') {
                this.setState({selectedProvince: { value: 'BC', label: 'British Columbia' }})
            }
        });
    }

    getAccount = async () => {
        const result = await KeyMarvel.getAccount();

        if (result && result.status === 200) {
            this.setState({
                email: result.data.email,
                firstName: result.data.firstName,
                lastName: result.data.lastName,
                profilePhoto: result.data.profilePhoto,
                address: result.data.address,
                phoneNumber: result.data.phoneNumber
            });

            if (result.data.address?.country == 'Canada') {
                this.setState({selectedCountry: { value: 'Canada', label: 'Canada' }})
            }
            if (result.data.address?.country == 'ON') {
                this.setState({selectedProvince: { value: 'ON', label: 'Ontario' }})
            }
            if (result.data.address?.country == 'BC') {
                this.setState({selectedProvince: { value: 'BC', label: 'British Columbia' }})
            }
        } else {
            console.log('error' + JSON.stringify('Failed to connect'));
        }
    };

    handleSubmit = async (e, error, values) => {
        console.log(values);
        console.log(this.state.selectedCountry?.value);

        this.setState({ error, values });


        if (typeof error?.length !== "undefined" && error?.length === 0) {

            if (values) {
                try {
                    let driverKey = sessionStorage.getItem("secretKey");
                    let driverId = sessionStorage.getItem("userId");

                    values.country = this.state.selectedCountry?.value ?? 'Canada';
                    values.province = this.state.selectedProvince?.value ?? '';

                    console.log(values);

                    fetch(config.DELIVERY_API + "/updateClientProfile/" + driverId + "/" + driverKey + "/", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(values),
                    })
                    .then(response => response.json())
                    .then(data => {
                        console.log('Success:', data);
                        this.setState({ errorMsg: "" });
                        this.toggle();
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        this.setState({ errorMsg: error });
                        this.toggle();
                    });
                } catch (error) {
                    console.log(error);
                }
            } else {
                console.log('values is null, update Driver error');
                this.setState({ errorMsg: 'values is null, update Driver error' });
            }
        }
    }

    render() {
        const { selectedProvince, selectedCountry } = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Contacts" breadcrumbItem="Profile" />

                        <Row>
                            <Col xl="4">
                                <Card className="overflow-hidden">
                                    <div className="bg-soft-primary">
                                        <Row>
                                            <Col xs="7">
                                                <div className="text-primary p-3">
                                                    <h5 className="text-primary">Profile</h5>
                                                    <p>Keeping it Easy!</p>
                                                </div>
                                            </Col>
                                            <Col xs="5" className="align-self-end">
                                                <img src={profile1} alt="" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <Row>
                                            <Col sm="12">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <img src={imageBasePath + this.state.profilePhoto + imageSize} alt="" className="img-thumbnail rounded-circle" />
                                                </div>
                                                <h5 className="font-size-15 text-truncate">{this.state.client.firstName} {this.state.client.lastName}</h5>
                                                <p className="text-muted mb-0 text-truncate">{this.state.client.superAdmin ? 'SUPER_ADMIN' : this.state.client.admin ? 'Admin' : 'Store Owner'}</p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>

                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Personal Information</CardTitle>

                                        <div className="table-responsive">
                                            <Table className="table-nowrap mb-0">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Full Name :</th>
                                                        <td>{this.state.client.firstName} {this.state.client.lastName}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Mobile :</th>
                                                        <td>{this.state.client.phoneNumber && this.state.client.phoneNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">E-mail :</th>
                                                        <td>{this.state.client.email && this.state.client.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Address :</th>
                                                        <td>{this.state.client.address &&
                                                            <>
                                                                <p className="text-muted mb-0">{this.state.client.address.addressLine1}</p>
                                                                <p className="text-muted mb-0">{this.state.client.address.addressLine2} {this.state.client.address.city}, {this.state.client.address.province}</p>
                                                            </>
                                                        }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>

                            </Col>

                            <Col xl="8">

                                <Card>
                                    <CardBody>
                                        <CardTitle>Edit Profile</CardTitle>
                                        <CardSubtitle>{this.state.email}</CardSubtitle>
                                        <br />
                                        <AvForm onSubmit={this.handleSubmit} id="editProfile" className="needs-validation" >
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="firstName">First Name*</Label>
                                                        <AvField
                                                            name="firstName"
                                                            placeholder="FirstName"
                                                            type="text"
                                                            errorMessage="Enter first name"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="fnname"
                                                            value={this.state.client.firstName}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="lastName">Last Name*</Label>
                                                        <AvField
                                                            name="lastName"
                                                            placeholder="LastName"
                                                            type="text"
                                                            errorMessage="Enter last name"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="lnname"
                                                            value={this.state.client.lastName}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="companyName">Company Name*</Label>
                                                        <AvField
                                                            name="companyName"
                                                            placeholder="Company / Store name"
                                                            type="text"
                                                            errorMessage="Enter company / store name"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="companyName"
                                                            value={this.state.client.companyName}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="email">Email</Label>
                                                        <AvField
                                                            name="email"
                                                            placeholder="Email"
                                                            type="email"
                                                            errorMessage="Enter provide a valid email"
                                                            className="form-control"
                                                            disabled
                                                            id="email"
                                                            value={this.state.client.email}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup>
                                                        <Label>Address</Label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="price">Address line*</Label>
                                                        <AvField
                                                            name="addressLine1"
                                                            placeholder="Address line"
                                                            type="text"
                                                            errorMessage="Please provide Address line"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="addressLine1"
                                                            value={this.state.client.address && this.state.client.address.addressLine1}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="postalCode">Address line 2</Label>
                                                        {this.state.client.address &&
                                                            <AvField
                                                                name="addressLine2"
                                                                placeholder="Enter Address line 2"
                                                                type="text"
                                                                errorMessage="Please provide Address line 2"
                                                                className="form-control"
                                                                // validate={{ required: { value: false } }}
                                                                id="addressLine2"
                                                                value={this.state.client.address && this.state.client.address.addressLine2}
                                                            />
                                                        }
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="postalCode">Postal code*</Label>
                                                        <AvField
                                                            name="postalCode"
                                                            placeholder="Postal code"
                                                            type="text"
                                                            errorMessage="Please provide Postal code."
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="postalCode"
                                                            value={this.state.client.address && this.state.client.address.postalCode}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="city">City*</Label>
                                                        <AvField
                                                            name="city"
                                                            placeholder="City"
                                                            type="text"
                                                            errorMessage="Please provide city."
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="city"
                                                            value={this.state.client.address && this.state.client.address.city}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="province">Province*</Label>
                                                        <Select
                                                            value={selectedProvince}
                                                            onChange={this.handleSelectProvince}
                                                            options={optionGroup1}
                                                            validate={{ required: { value: true } }}
                                                            id="province"
                                                            name="province"
                                                            errorMessage="Enter province"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="country">Country*</Label>
                                                        <Select
                                                            value={selectedCountry}
                                                            onChange={this.handleSelectCountry}
                                                            options={optionGroup2}
                                                            validate={{ required: { value: true } }}
                                                            id="country"
                                                            name="country"
                                                            errorMessage="Enter country"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Button color="primary" type="submit" onClick={this.handleSubmit}>Save changes</Button>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <div>
                            <Modal isOpen={this.state.modal} toggle={this.toggle} >
                                <ModalHeader toggle={this.toggle}>Successs</ModalHeader>
                                <ModalBody>
                                    Updated Successfully
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" onClick={() => {this.toggle(); this.nextPage();}}>OK</Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ContactsProfile;