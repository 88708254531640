export const IMAGEKIT_URL = "https://ik.imagekit.io/atagfh4iu/";
export const SUB_CATEGORY_DATA = [
  {
    subCategoryLabel: "subCategoryBurgers",
    value: "Burgers",
  },
  { subCategoryLabel: "subCategoryChicken", value: "Chicken" },
  { subCategoryLabel: "subCategoryFish`", value: "Fish n Chips" },
  { subCategoryLabel: "subCategoryPizza", value: "Pizza" },
  { subCategoryLabel: "subCategoryRoadhouse", value: "Roadhouse" },
  { subCategoryLabel: "subCategorySushi", value: "Sushi" },
  { subCategoryLabel: "subCategoryWings", value: "Wings" },
  { subCategoryLabel: "subCategoryAsian", value: "Asian" },
  { subCategoryLabel: "subCategoryCarribean", value: "Carribean" },
  { subCategoryLabel: "subCategoryChinese", value: "Chinese" },
  { subCategoryLabel: "subCategoryIndian", value: "Indian" },
  { subCategoryLabel: "subCategoryItalian", value: "Italian" },
  { subCategoryLabel: "subCategoryMexican", value: "Mexican" },
  { subCategoryLabel: "subCategoryMiddleEast", value: "Middle Eastern" },
  { subCategoryLabel: "subCategoryDesserts", value: "Desserts" },
];

export const minimumRequiredData = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
  { label: 10, value: 10 },
];
