import React, { Component } from "react";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

import KeyMarvel from "../../../helpers/keymarvel";

//i18n
import { withNamespaces } from 'react-i18next';

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      menu: false
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount = async () => {
    await this.getNotifications();
  };

  getNotifications = async () => {
    await KeyMarvel.getNotifications().then((result) => {
      if (result && result.status === 200) {
        this.setState({
          notifications: result.data
        });
      } else {
        console.log('error' + JSON.stringify('Failed to connect'));
      }
    })
  };

  showOrderDetails = (orderId) => {
    console.log('show order details for ', orderId);
    // update API
    KeyMarvel.updateNotifications(orderId).then((result) => {
      if (result && result.status === 200) {
        sessionStorage.setItem("orderId", orderId);
        //this.props.onclickOrder();
      } else {
        console.log('error' + JSON.stringify('Failed to connect'));
      }
    })
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu
    }));
  }
  render() {
    return (
      <React.Fragment>

        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="dropdown d-inline-block"
          tag="li"
        >
          {/*<DropdownToggle
            className="btn header-item noti-icon waves-effect"
            tag="button" id="page-header-notifications-dropdown">
            <i className="bx bx-bell bx-tada"></i>
            <span className="badge badge-danger badge-pill">{this.state.notifications.length}</span>
          </DropdownToggle>*/}

          <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {this.props.t('Notifications')} </h6>
                </Col>
              </Row>
            </div>

            <SimpleBar style={{ height: "230px" }}>
              {this.state.notifications.map((notification, key) =>
                <div key={"_order_" + key}>
                  {notification &&

                    <Link to={`/invoices-detail?orderid=${notification.orderId}`} className="text-reset notification-item">
                      <div className="media">
                        <div className="avatar-xs mr-3">
                          <span className="avatar-title bg-primary rounded-circle font-size-16">
                            <i className="bx bx-cart"></i>
                          </span>
                        </div>
                        <div className="media-body">
                          <h6 className="mt-0 mb-1">{this.props.t('A new order')}</h6>
                          <div className="font-size-12 text-muted">
                            <p className="mb-1">{notification.notes}</p>
                            <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <Moment fromNow>{notification.createdDateTime}</Moment> </p>
                          </div>
                        </div>
                      </div>
                    </Link>

                  }
                </div>
              )}
              {this.state.notifications.length == 0 &&
                <Link className="text-reset notification-item">
                  <div className="media">

                    <div className="media-body">
                      <h6 className="mt-0 mb-1">{this.props.t('No new Notification(s)')}</h6>
                    </div>
                  </div>
                </Link>
              }
            </SimpleBar>
            <div className="p-2 border-top">
              <Link
                className="btn btn-sm btn-link font-size-14 btn-block text-center"
                to="/ecommerce-orders"
              >
                {" "}
                {this.props.t('View All Orders')}{" "}
              </Link>
            </div>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(NotificationDropdown);
