import React, { Component } from "react";
import { Link, useLocation } from "react-router-dom";
import Dropzone from "react-dropzone";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavLink,
  NavItem,
  Alert,
  TabContent,
  TabPane,
} from "reactstrap";

import uploadFile from "react-aws-s3";
import { v4 as uuidv4 } from "uuid";
import KeyMarvel from "../../helpers/keymarvel";
import config from "../../config.json";
import { generateObject, generateState } from "../../helpers/utils";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Axios from "axios";
import { IMAGEKIT_URL } from "../../helpers/constants";
import { TableRepeater } from "../../components/Common/TableRepeater";
import { TableRepeaterFull } from "../../components/Common/TableRepeaterFull";
import classnames from "classnames";
import AddStoreForm from "../../components/Common/AddStoreForm";
import MenuTypeNotes from "../../components/Common/MenuTypeNotes";
class RestaurantCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activeTab: 1,
      pageLoading: true,
      rows: [],
      storeName: "",
      startDate: new Date(),
      endDate: new Date(),
      selectedFiles: [],
      categories: [],
      errorMsg: "",
      newProductName: "",
      s3FileName: "",
      fields: {},
      storeId: "",
      delivery_value: "",
      delivery_description: "",
      driverKey: "",
      driverId: "",
      cardPhoto: [],
      logoPhoto: [],
      cardPhotoFile: [],
      logoPhotoFile: [],
      menuType: [],
      menuLoading: false,
      menuTypesNotes: [],
    };

    this.config = {
      bucketName: "deliverydeals72",
      region: "us-east-1",
      accessKeyId: "AKIAVWGLA2DKT7H4NGFV",
      secretAccessKey: "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",
      s3Url: "https://deliverydeals72.s3.amazonaws.com" /* optional */,
    };

    this.fileSize = 10485760;
  }

  async componentDidMount() {
    let driverKey = sessionStorage.getItem("secretKey");
    let driverId = sessionStorage.getItem("userId");

    if (this.props.location?.state !== undefined) {
      sessionStorage.setItem(
        "user",
        JSON.stringify(this.props.location?.state)
      );

      const user = sessionStorage?.getItem("user");

      const userState = user !== undefined ? JSON.parse(user) : {};
      this.setState.bind(this)({
        driverKey,
        driverId,
        storeId: userState.id,
      });
      if (userState?.storeInfoFound) {
        await this.fetchStoreDetails(userState?.id, driverId, driverKey);
      }
      this.setState.bind(this)({
        pageLoading: false,
        storeInfoFound: userState?.storeInfoFound,
      });
    } else {
      this.props.history.push("store-grid");
    }
  }

  refresh = async () => {
    let userState = JSON.parse(sessionStorage?.getItem("user"));
    let driverKey = sessionStorage.getItem("secretKey");
    let driverId = sessionStorage.getItem("userId");
    this.setState({ menuLoading: true });
    await this.fetchStoreDetails(userState?.id, driverId, driverKey);
    this.setState({ menuLoading: false });
  };

  fetchStoreDetails = async (id, driverId, driverKey) => {
    const response = await Axios.get(
      config.DELIVERY_API +
        "getStoreInfo/" +
        driverId +
        "/" +
        driverKey +
        "/" +
        id
    ).catch((error) => {
      console.log("Error:", error.message);
      this.setState({ errorMsg: error.message });
    });
    if (response) {
      const stateObject = generateState(response);
      this.setState({
        ...stateObject,
      });
    } else {
    }
  };

  setFile = (incomingFile, type) => {
    this.setState({
      [type]: incomingFile,
    });
  };

  handleSubmit = async (event, error, values) => {
    this.setState.bind(this)({ loading: true });
    console.log(
      "values",
      values,
      this.state.cardPhotoFile,
      this.state.logoPhotoFile
    );
    this.setState.bind(this)({ error, values });

    const userState = sessionStorage?.getItem("user");
    const { id } = userState ? JSON.parse(userState) : {};

    if (typeof error?.length !== "undefined" && error?.length === 0) {
      try {
        let driverKey = sessionStorage.getItem("secretKey");
        let driverId = sessionStorage.getItem("userId");
        let sendObject = generateObject(
          values,
          this.state.rows,
          this.state.menuType
        );
        await this.addResInfo(
          sendObject,
          driverId,
          driverKey,
          values,
          id,
          this.state.rows
        );

        this.setState.bind(this)({
          fields: values,
          loading: false,
        });
      } catch (error) {
        console.log(error);
        this.setState.bind(this)({
          loading: false,
        });
      }
    }
    this.setState.bind(this)({
      loading: false,
    });
  };

  addResInfo = async (Obj, driverId, driverKey, values, storeId) => {
    const url = this.state.storeInfoFound
      ? "updateStoreInfo/"
      : "addStoreInfo/";

    const data = await this.addStoreDetails(
      Obj,
      driverId,
      driverKey,
      url
    ).catch((error) => {
      console.error("Error:", error);
      this.setState({ errorMsg: error.message });
    });

    if (data.data) {
      await this.handleUpload(
        values.storeName,
        this.state.cardPhotoFile,
        this.state.logoPhotoFile,
        storeId,
        this.state.rows,
        driverId,
        driverKey
      );

      let sendObject = generateObject(
        values,
        this.state.rows,
        this.state.menuType,
        this.state.logoPhoto,
        this.state.cardPhoto
      );
      await this.updateStoreDetails(sendObject, driverId, driverKey).catch(
        (error) => {
          console.error("Error:", error);
          this.setState({ errorMsg: error.message });
        }
      );

      if (!this.state.storeInfoFound) {
        this.props.history.push("/store-grid");
      }
    }
  };

  updateStoreMenuDetails = async (Object, driverId, driverKey) => {
    const userState = JSON.parse(sessionStorage.getItem("user"));
    return await Axios.post(
      config.DELIVERY_API +
        "updateStoreMenuInfo/" +
        driverId +
        "/" +
        driverKey +
        "/" +
        userState?.id,
      JSON.stringify({ menuItems: Object }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  handleUpload = async (storeName, card, logo, storeId, menuItems) => {
    if (card.size) {
      let cardSplitName = card.name?.split(".");
      let cardFileName = `${storeName}-${uuidv4()}`;
      const resultCard = await uploadFile(
        card,
        cardFileName, this.config
      ).catch((error) => {
        console.error(error);
        this.setState({ errorMsg: "Service error" });
      });
      if (resultCard) {
        console.log("uploaded details " + JSON.stringify(resultCard));
        if (resultCard && resultCard.status === 204) {
          this.setState({
            cardPhoto: IMAGEKIT_URL + resultCard.key,
          });
        }
      }
    }

    if (logo.size) {
      let logoSplitName = logo.name?.split(".");
      let logoFileName = `${storeName}-${uuidv4()}`;
      const logoResult = await uploadFile(
        logo,
        logoFileName, this.config
      ).catch((error) => {
        console.error(error);
        this.setState({ errorMsg: "Service error" });
      });
      if (logoResult) {
        console.log("uploaded details " + JSON.stringify(logoResult));
        if (logoResult && logoResult.status === 204) {
          this.setState({
            logoPhoto: IMAGEKIT_URL + logoResult.key,
          });
        }
      }
    }
  };

  addStoreDetails = async (Object, driverId, driverKey, url) => {
    const userState = JSON.parse(sessionStorage.getItem("user"));
    return await Axios.post(
      config.DELIVERY_API +
        url +
        driverId +
        "/" +
        driverKey +
        "/" +
        userState.id,
      JSON.stringify(Object),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  updateStoreDetails = async (Object, driverId, driverKey) => {
    const userState = JSON.parse(sessionStorage.getItem("user"));
    return await Axios.post(
      config.DELIVERY_API +
        "updateStoreInfo/" +
        driverId +
        "/" +
        driverKey +
        "/" +
        userState.id,
      JSON.stringify(Object),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content" style={{ marginTop: "0px" }}>
          {this.state.pageLoading && (
            <div
              style={{
                height: "100vh",
                background: "rgb(255 252 252 / 1%)",
                zIndex: 9999,
                textAlign: "center",
              }}
            >
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Stores" gotoLink="store-grid"
              breadcrumbItem="Add / Update Store Info"
            />
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: this.state.activeTab === 1,
                  })}
                  onClick={() => {
                    this.toggle(1);
                  }}
                >
                  Store Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: this.state.activeTab === 2,
                  })}
                  onClick={() => {
                    this.toggle(2);
                  }}
                  disabled={this.state.storeInfoFound ? false : true}
                >
                  Menu(s) or Product(s)
                </NavLink>
              </NavItem>
              {/*<NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: this.state.activeTab === 3,
                  })}
                  onClick={() => {
                    this.toggle(3);
                  }}
                  disabled={this.state.menuType.length ? false : true}
                >
                  Menu or Product Notes
                </NavLink>
              </NavItem>*/}
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: this.state.activeTab === 4,
                  })}
                  onClick={() => {
                    this.toggle(4);
                  }}
                  disabled={this.state.storeInfoFound ? false : true}
                >
                  Menu or Product list
                </NavLink>
              </NavItem>
            </Nav>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId={1} className="p-3">
                        <AddStoreForm
                          state={this.state}
                          handleSubmit={this.handleSubmit}
                          setFile={this.setFile}
                          updateState={this.setState.bind(this)}
                        />
                      </TabPane>
                      <TabPane tabId={2} className="p-3">
                        <TableRepeater
                          refresh={this.refresh}
                          update={this.state.storeInfoFound}
                          rows={this.state.rows}
                          updateState={this.setState.bind(this)}
                          storeId={this.state.storeId}
                          storeName={this.state.storeName}
                          driverId={this.state.driverId}
                          driverKey={this.state.driverKey}
                          menuType={this.state.menuType}
                        />
                      </TabPane>
                      <TabPane tabId={3} className="p-3">
                        <MenuTypeNotes
                          menuTypes={this.state.menuType}
                          menuTypesNotes={this.state.menuTypesNotes}
                          refresh={this.refresh}
                          storeId={this.state.storeId}
                          driverId={this.state.driverId}
                          driverKey={this.state.driverKey}
                        />
                      </TabPane>
                      <TabPane tabId={4} className="p-3">
                        <TableRepeaterFull
                          refresh={this.refresh}
                          update={this.state.storeInfoFound}
                          rows={this.state.rows}
                          updateState={this.setState.bind(this)}
                          storeId={this.state.storeId}
                          driverId={this.state.driverId}
                          driverKey={this.state.driverKey}
                          menuType={this.state.menuType}
                        />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>

                {this.state.errorMsg ? (
                  <Alert color="danger">{this.state.errorMsg}</Alert>
                ) : (
                  ""
                )}
                {false && (
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() =>
                        this.setState.bind(this)({ modal_scroll: false })
                      }
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.handleUpload}
                    >
                      Upload image
                    </button>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default RestaurantCreate;
