import React, { Component } from "react";
import { Link, useLocation } from "react-router-dom";
import Dropzone from "react-dropzone";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Form,
    FormGroup,
    Label,
    Button,
    Alert,
    Modal, ModalBody, ModalHeader, ModalFooter
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import S3 from "react-aws-s3";
import { v4 as uuidv4 } from "uuid";
import KeyMarvel from "../../helpers/keymarvel";
import config from "../../config.json";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class ProjectsCreate extends Component {
    constructor() {
        super();
        this.state = {
            enablePassword: false,
        };
    }

    componentDidMount() {
        const { match, location, history } = this.props;
        console.log(location);
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };

    handleSubmit = (event, error, values) => {
        this.setState({ error, values });

        console.log("values", values);

        if (typeof error?.length !== "undefined" && error?.length === 0) {

            if (values) {
                try {
                    let driverKey = sessionStorage.getItem("secretKey");
                    let driverId = sessionStorage.getItem("userId");
                    values.role = "DRIVER";
                    values.email = "unknown@testemail.com";
                    this.addNewDriver(values, driverId, driverKey);
                } catch (error) {
                    console.log(error);
                }
            }
        }
    };

    addNewDriver = async (values, driverId, driverKey) => {
        fetch(config.DELIVERY_API + "addDriver/" + driverId + "/" + driverKey, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    this.setState({ errorMsg: data.message || data.error });
                } else {
                    this.setState({ errorMsg: "" });
                    this.toggle();
                    this.form && this.form.reset();
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                this.setState({ errorMsg: error });
            });
    };

    enablePassword = () => {
        this.setState({
            enablePassword: !this.state.enablePassword
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content" style={{marginTop:"0px"}}>
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Drivers" breadcrumbItem="Add Driver" />

                        {this.state.errorMsg &&
                            <Alert color="danger">{this.state.errorMsg}</Alert>
                        }

                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <AvForm
                                            onSubmit={this.handleSubmit}
                                            id="editDriverForm"
                                            className="needs-validation"
                                            ref={(c) => (this.form = c)}
                                        >
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="firstName">First name*</Label>
                                                        <AvField
                                                            name="firstName"
                                                            placeholder="First name"
                                                            type="text"
                                                            errorMessage="Enter first name"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="firstName"
                                                            value={this.state.firstName}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="lastName">Last name*</Label>
                                                        <AvField
                                                            name="lastName"
                                                            placeholder="Last name"
                                                            type="text"
                                                            errorMessage="Enter last name"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="lastName"
                                                            value={this.state.lastName}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="phoneNumber">Mobile number*</Label>
                                                        <AvField
                                                            name="phoneNumber"
                                                            placeholder="Mobile number"
                                                            type="phoneNumber"
                                                            errorMessage="Please provide a valid mobile number."
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="phoneNumber"
                                                            value={this.state.phoneNumber}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="password">Password*</Label>
                                                        <AvField
                                                            name="password"
                                                            placeholder="Password"
                                                            type={this.state.enablePassword ? "text" : "password"}
                                                            errorMessage="Enter password"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="password"
                                                            autoComplete='new-password'
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="2">
                                                    <FormGroup>
                                                        <Label htmlFor="passwordBtn">{" "}</Label>
                                                        <Button style={{marginTop: '25px'}} color="primary" type="button" onClick={this.enablePassword}>{this.state.enablePassword ? "Hide password" : "Show password"}</Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="driverLicenseNumber">
                                                            Driver license number
                                                        </Label>
                                                        <AvField
                                                            name="driverLicenseNumber"
                                                            placeholder="Driver license number"
                                                            type="text"
                                                            className="form-control"
                                                            validate={{ required: { value: false } }}
                                                            id="driverLicenseNumber"
                                                            value={this.state.driverLicenseNumber}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Button
                                                color="primary"
                                                type="submit"
                                                onClick={this.handleSubmit}
                                            >
                                                Add driver
                                            </Button>
                                        </AvForm>
                                    </CardBody>
                                </Card>


                            </Col>
                        </Row>

                        <div>
                            <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-dialog modal-dialog-centered">
                                <ModalHeader toggle={this.toggle}>Successs</ModalHeader>
                                <ModalBody>Added driver successfully</ModalBody>
                                <ModalFooter>
                                    <Button
                                        color="primary"
                                        tag={Link}
                                        to={{
                                            pathname: "/contacts-grid",
                                        }}
                                        onClick={this.toggle}
                                    >
                                        OK
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ProjectsCreate;
