import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Container,
  Input,
  FormFeedback,
} from "reactstrap";
import WrappedInput from "./Input";
import { useFieldArray } from "react-hook-form";
export const PopupOptionsRepeater = ({ optionID, register, control }) => {
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `menuOptions[${optionID}].popupMenuOptions`, // unique name for your Field Array
  });
  const handleAddRowPopupOptions = (size) => {
    append({
      name: "",
      price: "",
    });
  };

  return (
    <Container>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Popup Options</CardTitle>
              {fields.map((item, idx) => (
                <div className="border border-primary p-2 my-2" key={idx}>
                  <Row id={"popups" + idx} className="mx-2">
                    <h4>Popup option {idx + 1}</h4> <br />
                    <div data-repeater-list="group-a" className="w-100">
                      <Row data-repeater-item>
                        <Col lg="4" className="form-group">
                          <Label htmlFor={"name"}>Name</Label>
                          <WrappedInput
                            type="text"
                            placeholder="e.g Cheddar cheese"
                            defaultValue={item.name}
                            {...register(
                              `menuOptions[${optionID}].popupMenuOptions[${idx}].name`,
                              {
                                required: true,
                              }
                            )}
                          />
                        </Col>

                        <Col lg="4" className="form-group">
                          <Label htmlFor={"popup-price"}>Price</Label>
                          <WrappedInput
                            type="text"
                            {...register(
                              `menuOptions[${optionID}].popupMenuOptions[${idx}].price`,
                              {
                                required: true,
                              }
                            )}
                          />
                        </Col>

                        <Col lg="4" className="form-group align-self-center">
                          <Button
                            onClick={(e) => remove(idx)}
                            color="primary"
                            className="mt-3"
                            style={{ width: "100%" }}
                          >
                            Delete popup options
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Row>
                </div>
              ))}

              <Button
                onClick={() => {
                  handleAddRowPopupOptions();
                }}
                color="success"
              >
                Add popup options
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
