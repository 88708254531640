import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Container,
  Input,
} from "reactstrap";
import { PopupOptionsRepeater } from "./popup-repeater";
import Select from "react-select";
import { minimumRequiredData } from "../../helpers/constants";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";
import WrappedInput from "./Input";
export const MenuOptionsRepeater = ({ rows, setState, itemID }) => {
  const { fields, append, remove, register, control } = useFormContext();

  const handleAddRowMenuOptions = () => {
    append({
      title: "",
      popupMenuOptions: [],
      subTitle: "",
      limit: 0,
    });
  };

  return (
    <Container>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Options</CardTitle>
              {fields &&
                fields.map((item, idx) => (
                  <div className="border border-primary p-2 my-2" key={idx}>
                    <Row id={"options" + idx} className="mx-2">
                      <h4>Option {idx + 1}</h4>
                      <div data-repeater-list="group-a" className="w-100">
                        <Row data-repeater-item>
                          <Col lg="6" className="form-group">
                            <Label htmlFor={"title"}>Title</Label>
                            <WrappedInput
                              type="text"
                              placeholder="e.g Choose your cheese?"
                              {...register(`menuOptions[${idx}].title`, {
                                required: true,
                              })}
                            />
                          </Col>

                          <Col lg="6" className="form-group">
                            <Label htmlFor={"subTitle"}>SubTitle</Label>
                            <WrappedInput
                              type="text"
                              placeholder="e.g Any 3"
                              {...register(`menuOptions[${idx}].subTitle`, {
                                required: true,
                              })}
                            />
                          </Col>
                          <Col lg="6" className="form-group">
                            <Label htmlFor={"limit"}>Minimum Required</Label>
                            <WrappedInput
                              type="text"
                              placeholder="e.g Any 3"
                              {...register(`menuOptions[${idx}].limit`, {
                                required: true,
                              })}
                            />
                          </Col>

                          <PopupOptionsRepeater
                            setState={setState}
                            optionID={idx}
                            itemID={itemID}
                            register={register}
                            control={control}
                          />

                          <Col lg="2" className="form-group align-self-center">
                            <Button
                              onClick={(e) => remove(idx)}
                              color="primary"
                              className="mt-3"
                              style={{ width: "100%" }}
                            >
                              Delete option
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Row>
                  </div>
                ))}

              <Button
                onClick={() => {
                  handleAddRowMenuOptions();
                }}
                color="success"
              >
                Add option
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
