import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Table, FormGroup, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import KeyMarvel from "../../helpers/keymarvel";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Image
import logo from "../../assets/images/logo.png";

class InvoiceDetail extends Component {
    constructor() {
        super();
        this.state = {
            transaction: {},
            orderId: "",
        }
        this.printInvoice.bind(this);
    }

    componentDidMount = async () => {
        // let orderId = sessionStorage.getItem("orderId");
        let orderId = new URLSearchParams(window.location.search).get("orderid");
        console.log("passed order id is ", orderId);
        this.setState({
            orderId
        });
        
        await this.getTransaction(orderId);

        //transaction/1001/transaction/zce9-JYFt?storeSecretKey=1225882323123
    };

    getTransaction = async (string) => {
        await KeyMarvel.getTransaction(string).then((result) => {
            if (result && result.status === 200) {
                this.setState({
                    transaction: result.data
                });

                KeyMarvel.updateNotifications(this.state.orderId).then((result) => {
                    if (result && result.status === 200) {
                        console.log('successfully updated the notification');
                    } else {
                        console.log('error' + JSON.stringify('Failed to connect (update notify)'));
                    }
                })
            } else {
                console.log('error' + JSON.stringify('Failed to connect (get order details)'));
            }
        })
    };

    formatDate(string) {
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(string).toLocaleDateString([], options);
    }

    formatTime(string) {
        return new Date(string).toLocaleTimeString();
    }

    //Print the Invoice
    printInvoice() {
        window.print();
    }

    render() {
        let transaction = this.state.transaction;
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Invoices" breadcrumbItem="Order Details" />

                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div className="invoice-title">
                                            {transaction.orderDetails && transaction.orderDetails.cartId && <h3 className="float-right font-size-16">Order number # {transaction.orderDetails.cartId.toUpperCase()}</h3>}<br/>
                                            <div className="mb-4">
                                                <img src={logo} alt="logo" height="70" />
                                            </div>
                                        </div>
                                        <hr />
                                        {false &&
                                        <Row>
                                            <Col xs="6">
                                                {transaction.orderDetails && transaction.orderDetails.cartId && <p>Customer reference number # {transaction.orderDetails.cartId.toUpperCase()}</p>}
                                            </Col>
                                        </Row> }
                                        <Row>
                                            <Col xs="6">
                                                <address>
                                                    <strong>Billed To:</strong><br />
                                                    {transaction.billingName}<br />
                                                    {transaction.billingAddress && <>{transaction.billingAddress.postalCode}, {transaction.billingAddress.country}</>}
                                                </address>
                                                <address>
                                                    <strong>Contact Phone number:</strong><br />
                                                    {transaction.orderDetails && <>{transaction.orderDetails.deliveryPhoneNumber}</>}
                                                </address>
                                            </Col>
                                            <Col xs="6" className="text-right">
                                                <address>
                                                    <strong>Delivery To:</strong><br />
                                                    {transaction.orderDetails && transaction.orderDetails.deliveryAddress &&
                                                        <>
                                                            {transaction.orderDetails.deliveryAddress.addressLine1}
                                                            <br/>
                                                            {transaction.orderDetails.deliveryAddress.addressLine2}
                                                            <br />
                                                            {transaction.orderDetails.deliveryAddress.city}
                                                            <br />
                                                            {transaction.orderDetails.deliveryAddress.postalCode}
                                                        </>
                                                    }
                                                </address>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12" className="text-right">
                                                <address>
                                                    <strong>Delivery Options (Choosen by the Customer)</strong><br />
                                                    {transaction.orderDetails && transaction.orderDetails.delivery &&
                                                        <>
                                                            {transaction.orderDetails.delivery.deliveryDate.toUpperCase()},&nbsp;{transaction.orderDetails.delivery.deliveryTime},&nbsp;
                                                            {transaction.orderDetails.delivery.deliveryOption == 'curbside' ? 'Curbside' : 'Door delivery'}
                                                            <br />
                                                            {transaction.orderDetails.delivery.notes.toUpperCase()}
                                                        </>
                                                    }
                                                </address>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="6" className="mt-3">
                                                <address>
                                                    <strong>Payment Method:</strong><br />
                                                    {transaction.paymentMethod} ending **** {transaction.paymentCard}
                                                </address>
                                            </Col>
                                            <Col xs="6" className="mt-3 text-right">
                                                <address>
                                                    <strong>Order Date:</strong><br />
                                                    {transaction.formatDate}<br /><br />
                                                </address>
                                            </Col>
                                        </Row>
                                        <div className="py-2 mt-3">
                                            <h3 className="font-size-15 font-weight-bold">Order summary</h3>
                                        </div>
                                        <div className="table-responsive">
                                            <Table className="table-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "70px" }}>No.</th>
                                                        <th>Item</th>
                                                        <th>Quantity</th>
                                                        <th className="text-right">Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {transaction.orderDetails && transaction.orderDetails.products.map((product, key) =>
                                                        <tr key={key}>
                                                            <td>{key+1}</td>
                                                            {product.brand && <td>{product.brand} - {product.name} {product.specifications}</td>}
                                                            {!product.brand && <td>No brand - {product.name} {product.specifications}</td>}
                                                            <td>{product.quantity}</td>
                                                            <td className="text-right">${product.price}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className="py-2 mt-3">
                                            <h3 className="font-size-15 font-weight-bold">Order changes</h3>
                                        </div>
                                        <div>
                                            <AvForm className="needs-validation" >
                                                <Row className="form-group">
                                                    <label htmlFor="txtAddress1Billing" className="col-lg-3 col-form-label">Summary of Changes</label>
                                                    <Col lg={9}>
                                                        <textarea id="txtAddress1Billing" name="txtAddress1Billing" rows="4" className="form-control"></textarea>
                                                    </Col>
                                                </Row>
                                                <Row className="form-group">
                                                    <label htmlFor="finalAmount" className="col-lg-3 col-form-label">Final amount (After any changes)</label>
                                                    <Col lg={3}>
                                                        <input type="text" id="finalAmount" name="finalAmount" className="form-control"></input>
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        </div>
                                        <div className="d-print-none">
                                            <div className="float-right">
                                                <Link to="#" className="btn btn-danger waves-effect waves-light mr-2">Update Order</Link>
                                                <Link to="#" onClick={this.printInvoice} className="btn btn-success waves-effect waves-light mr-2"><i className="fa fa-print"></i></Link>
                                                <Link to="/ecommerce-orders" className="btn btn-primary w-md waves-effect waves-light">&lt; Back</Link>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default InvoiceDetail;