import firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyD6-E84jUlno5QhHbSK_ar2BTFlc3cumRk",
  authDomain: "deliverydeals-3d5e8.firebaseapp.com",
  projectId: "deliverydeals-3d5e8",
  storageBucket: "deliverydeals-3d5e8.appspot.com",
  messagingSenderId: "703226513151",
  appId: "1:703226513151:web:3e33313f05c07735a36eca",
  measurementId: "G-Z0BZFXRV8C"
};

let self;


firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

export const getToken = () => {
  if (messaging == null) { return null };
  return messaging.getToken({ vapidKey: 'BF3rHC369DzYMN6LImv5k0nKsucw_QndVrjk2TUFuyYfsefy0vIFmyse2UIXFcKGslV8C8fILe7BzOwjRNUvJUc' }).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      //setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
      sessionStorage.setItem("deviceToken", currentToken);
    } else {
      console.log('No registration token available. Request permission to generate one.');
      //setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });

    // [START messaging_on_background_message]
    messaging.onBackgroundMessage((payload) => {
      console.log('[firebase-messaging-sw.js] Received background message ', payload);
      // Customize notification here
      const notificationTitle = 'Background Message Title';
      const notificationOptions = {
        body: 'Background Message body.',
        icon: '/firebase-logo.png'
      };

      self.registration.showNotification(notificationTitle,
        notificationOptions);
    });
    // [END messaging_on_background_message]
  });