import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { CustomInput, Label, FormGroup, Input } from "reactstrap";
import WrappedInput from "./Input";
const FileUpload = ({
  file,
  setFile,
  FieldName,
  title,
  idx = "",
  required = false,
  ...props
}) => {
  return (
    <>
      <Label for="file">{title}</Label>
      <input className="form-control-file" {...props} type="file" />
    </>
  );
};

export default FileUpload;
