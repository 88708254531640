import React, { Component } from 'react';
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import { Container, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, Button  } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import config from "../../config.json";
import axios from "axios";

class StoreGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stores: [],
            modal: false,
            selectedStoreId: '',
            selectedStoreName: '',
        }
        this.togglemodal.bind(this);
    }

    togglemodal = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    confirmDelete = (id, name) => {
        this.setState({
            selectedStoreId: id,
            selectedStoreName: name,
            modal: true
        })
    }

    deleteStore = () => {
        let driverKey = sessionStorage.getItem("secretKey");
        let driverId = sessionStorage.getItem("userId");
        fetch(
            config.DELIVERY_API +
            "deleteStore/" +
            driverId +
            "/" +
            driverKey +
            "/" +
            this.state.selectedStoreId
        )
            .then((response) => response.json())
            .then((data) => {
                this.getAllStores(driverId, driverKey);
            });
    }

    deleteUser(id, name) {
        console.log(id)
        this.confirmDelete(id, name);
    }

    getAllStores = async (driverId, driverKey) => {
        this.setState({
            loading: true,
        })
        try {
            console.log(config.DELIVERY_API)
            const response = await axios.get(config.DELIVERY_API + "findAllStores/" + driverId + "/" + driverKey, {
                pageNo: 0,
                pageSize: 100
            })
            if (response.status === 200) {
                var data = response.data;
                var rows = data.map(store => ({
                    storeName: store.companyName,
                    phoneNumber: store.phoneNumber,
                    address: store.address.addressLine1 + ", " + store.address.postalCode + ", " + store.address.city,
                    actions: <>
                        
                        <Link data-toggle="tooltip" data-placement="bottom" title="Edit store info" to={{
                                        pathname: '/edit-store',
                                        state: store
                                    }} id={"edit" + store.id} className="text-body font-size-18">
                            <i style={{ color: 'green' }} className="bx bx-edit"></i>
                        </Link>
                        <span>{"   "}</span>
                        {store.storeInfoFound ? (
                            <Link
                                to={{
                                pathname: "/resturant-create",
                                state: store,
                                }}
                                id={"edit-menu" + store.id} title="Edit Store's Menu" className="text-body font-size-18">
                                <i className="bx bx-restaurant"></i>
                            </Link>
                            ) : (
                            <Link
                                to={{
                                pathname: "/resturant-create",
                                state: store,
                                }}
                                id={"add-menu" + store.id} title="Add Store's Menu" className="text-body font-size-18">
                                <i className="bx bx-restaurant"></i>
                            </Link>
                        )}
                        <Link data-toggle="tooltip" to="#" data-placement="bottom" title="Delete store" onClick={() => this.deleteUser(store.id, store.companyName)} className="text-body font-size-18">
                            <i style={{ color: 'red' }} className="bx bx-trash"></i>
                        </Link>
                    </>,
                }));
                var columns = [
                    {
                      label: "Name",
                      field: "storeName",
                      sort: "asc"
                    },
                    {
                        label: "Mobile number",
                        field: "phoneNumber"
                    },
                    {
                        label: "Address",
                        field: "address"
                    },
                    {
                        label: "Actions",
                        field: "actions"
                    },
                ];

                var storeDatas = {rows, columns}

                console.log(storeDatas);
                this.setState({
                    stores: storeDatas,
                    modal: false,
                    loading: false
                })
            }

        } catch (error) {
            this.setState({
                loading: false,
            })
        }
    }

    async componentDidMount() {
        let driverKey = sessionStorage.getItem("secretKey");
        let driverId = sessionStorage.getItem("userId");
        this.setState({
            driverKey,
            driverId
        })
        // await this.getBasicInfo('notification', driverId, driverKey);
        this.getAllStores(driverId, driverKey)
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumbs */}
                        <Breadcrumbs breadcrumbItem="Stores" />

                        <Row>
                            <Col xs="12">
                                <div>
                                    <span className="text-body font-size-16">Total stores : {this.state.stores.rows ? this.state.stores.rows.length : 0}</span>
                                    <MDBDataTable class="table table-dark" entries={50} exportToCSV small noBottomColumns responsive hover striped data={this.state.stores} />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="12">
                                <div className="text-center my-3">
                                    <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                </div>
                            </Col>
                        </Row>

                        <div>
                            <Modal isOpen={this.state.modal} toggle={this.togglemodal} className="modal-dialog modal-dialog-centered">
                                <ModalHeader toggle={this.toggle}>Alert! Do you want to delete the Store?</ModalHeader>
                                <ModalBody>Store will be removed permanetly and App / Web access will be revoked after this deletion. Selected store is {<span style={{ color: 'red' }}>{this.state.selectedStoreName}</span>}</ModalBody>
                                <ModalFooter>
                                    <Button
                                        color="danger"
                                        tag={Link}
                                        onClick={this.deleteStore}
                                    >
                                        OK
                                    </Button>
                                    <Button color="secondary" onClick={this.togglemodal}>Cancel</Button>
                                </ModalFooter>
                            </Modal>
                        </div>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default StoreGrid;