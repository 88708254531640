import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  CardText,
  CardSubtitle,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  FormGroup,
  Label,
  Form,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import KeyMarvel, { imageBasePath, imageSize } from "../../helpers/keymarvel";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Dropzone from "react-dropzone";
import S3 from "react-aws-s3";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class EcommerceCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: [],
      categories: [],
      selectedProduct: {},
      selectedCategory: "Select products by category",
      toggleSwitch: true,
      fields: {
        name: "",
        category: "",
        brand: "",
        price: "",
        taxable: true,
      },
      selectedFiles: [],
    };
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
    this.countUP.bind(this);
    this.countDown.bind(this);
    this.removeCartItem.bind(this);
    this.tog_standard = this.tog_standard.bind(this);
    this.tog_scroll = this.tog_scroll.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.config = {
      bucketName: "deliverydeals72",
      region: "us-east-1",
      accessKeyId: "AKIAVWGLA2DKT7H4NGFV",
      secretAccessKey: "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",
      s3Url: "https://deliverydeals72.s3.amazonaws.com" /* optional */,
    };
    this.fileSize = 10000000;
  }

  handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: files });
  };

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }

  tog_scroll() {
    this.setState((prevState) => ({
      modal_scroll: !prevState.modal_scroll,
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  componentDidMount() {
    try {
      KeyMarvel.getStores()
        .then((res) => {
          this.setState({
            categories: res.data,
          });
          console.log(res);
          if (res.data.length > 0) {
            KeyMarvel.getProducts(res.data[0], 0).then((responseProduct) => {
              this.setState({
                productList: responseProduct.data,
                selectedProduct: responseProduct.data[0],
                selectedCategory: responseProduct.data[0].category,
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("could not fetch the payload .." + error);
    }
  }

  removeCartItem = (id) => {
    let productList = this.state.productList;

    var filtered = productList.filter(function (item) {
      return item.id !== id;
    });

    this.setState({ productList: filtered });
  };

  countUP = (id, prev_data_attr) => {
    this.setState({
      productList: this.state.productList.map((p) =>
        p.id === id ? { ...p, data_attr: prev_data_attr + 1 } : p
      ),
    });
  };

  countDown = (id, prev_data_attr) => {
    this.setState({
      productList: this.state.productList.map((p) =>
        p.id === id ? { ...p, data_attr: prev_data_attr - 1 } : p
      ),
    });
  };

  handleCategorySelection = (item) => {
    //Generates data to add to cart
    const category = item;
    this.setState({
      selectedCategory: item,
    });

    if (item) {
      KeyMarvel.getProducts(item, 0).then((responseProduct) => {
        this.setState({
          productList: responseProduct.data,
          selectedProduct: responseProduct.data[0],
        });
      });
    }
  };

  makeInventoryLow = (item) => {
    const findProduct = this.state.productList.filter(
      (product) => product.id === item
    );
    findProduct[0].inventory = 10;
    if (item) {
      KeyMarvel.updateProduct(findProduct[0])
        .then((responseProduct) => {
          this.setState({
            selectedProduct: findProduct[0],
          });
        })
        .catch((error) => {
          console.error("Error during service updating the product:", error);
        });
    }
  };

  makeInventoryHigh = (item) => {
    const findProduct = this.state.productList.filter(
      (product) => product.id === item
    );
    findProduct[0].inventory = 50;
    if (item) {
      KeyMarvel.updateProduct(findProduct[0]).then((responseProduct) => {
        this.setState({
          selectedProduct: findProduct[0],
        });
      });
    }
  };

  editProduct = (item) => {
    const findProduct = this.state.productList.filter(
      (product) => product.id === item
    );
    if (item) {
      this.setState({
        selectedProduct: findProduct[0],
      });

      this.tog_scroll();
    }
  };

  handleSubmit(event, errors, values) {
    this.setState({ errors, values });

    const findProduct = this.state.selectedProduct;
    if (findProduct) {
      findProduct.name = values.name;
      findProduct.brand = values.brand;
      findProduct.category = values.category;
      findProduct.price = values.price;
      findProduct.inventory = values.quantity;
      findProduct.specifications = [];
      findProduct.specifications[0] = values.data;
      findProduct.taxable = values.taxable;

      KeyMarvel.updateProduct(findProduct).then((result) => {
        if (result && result.status === 200) {
          console.log("updated product" + result);
          this.tog_scroll();
        } else {
          console.log("update product error" + result.error);
          this.setState({ errorMsg: result.error });
        }
      });
    }
  }

  cancel = (item) => {
    const findProduct = this.state.productList.filter(
      (product) => product.id === item
    );
    this.setState({
      selectedProduct: findProduct[0],
    });
  };

  handleUpload = () => {
    if (this.state.selectedFiles[0]) {
      if (this.state.selectedFiles[0].size <= this.fileSize) {
        const date = new Date();
        //const newProductName = this.state.selectedFiles[0].path;

        console.log("upload file name ", this.state.selectedFiles[0].path);

        const ReactS3Client = new S3(this.config);
        ReactS3Client.uploadFile(this.state.selectedFiles[0])
          .then((result) => {
            console.log("uploaded details " + JSON.stringify(result));
            if (result && result.status === 204) {
              this.setState({
                selectedFiles: [],
                s3FileName: result.key,
              });

              console.log("update s3 file name" + this.state.s3FileName);

              let product = this.state.selectedProduct;
              product.images = [];
              product.images[0] = this.state.s3FileName;
              KeyMarvel.updateProductImage(product).then((result) => {
                if (result && result.status === 200) {
                  console.log("updated product image" + result);
                  this.setState({
                    selectedProduct: product,
                  });
                } else {
                  console.log("update product image error" + result.error);
                  this.setState({ errorMsg: result.error });
                }
              });
            }
          })
          .catch((error) => {
            console.error(error);
            this.setState({ errorMsg: "Service error" });
          });

        this.setState({
          errorMsg: "",
        });
      } else {
        this.setState({
          errorMsg:
            "File Size too Large , Please upload an image less than 10 MB",
          selectedFiles: [],
        });
      }
    } else {
      this.setState({
        errorMsg: "Select a file",
      });
    }
  };

  render() {
    const selectedProduct = this.state.selectedProduct;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Store" breadcrumbItem="Products" />
            <Row>
              <Col sm={6}>
                <Dropdown
                  isOpen={this.state.btnInfo1}
                  toggle={() =>
                    this.setState({ btnInfo1: !this.state.btnInfo1 })
                  }
                  direction="right"
                >
                  <DropdownToggle className="btn btn-info" caret>
                    {this.state.selectedCategory}{" "}
                    <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    {this.state.categories.map((category) => (
                      <DropdownItem
                        key={category}
                        onClick={() => {
                          this.handleCategorySelection(category);
                        }}
                      >
                        {category}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
            <Row>
              <hr />
            </Row>
            <Row>
              {this.state.productList.map((product) => (
                <Col key={product.id} mg={6} xl={3}>
                  <Card>
                    <CardBody>
                      <CardTitle>
                        {product.brand && <>{product.brand}'s</>} {product.name}{" "}
                        <br />{" "}
                        <code className="highlighter-rouge">
                          $ {product.price}
                        </code>
                      </CardTitle>
                      <CardSubtitle className="mb-3">
                        Stock: {product.inventory},{" "}
                        {product.taxable ? "Tax" : "No Tax"}
                        <br />
                        Specifications: {product.specifications}
                        <br />
                        Category: {product.category}
                      </CardSubtitle>
                    </CardBody>
                    <CardImg
                      top
                      className="img-fluid"
                      src={imageBasePath + product.images[0] + imageSize}
                      alt="Delivery_Deals"
                    />
                    <CardBody>
                      <UncontrolledDropdown>
                        <DropdownToggle className="btn btn-success" caret>
                          Edit <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem
                            onClick={() => {
                              this.makeInventoryLow(product.id);
                            }}
                          >
                            Make inventory low
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              this.makeInventoryHigh(product.id);
                            }}
                          >
                            Make inventory high
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              this.editProduct(product.id);
                            }}
                          >
                            Edit product
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              this.cancel(product.id);
                            }}
                          >
                            Cancel
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row>
              <Col sm={6} md={4} xl={3} className="mt-4">
                <Modal
                  isOpen={this.state.modal_scroll}
                  toggle={this.tog_scroll}
                  scrollable={true}
                >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0">{selectedProduct.name}</h5>
                    <button
                      type="button"
                      onClick={() => this.setState({ modal_scroll: false })}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Card>
                      <div className="media mb-4">
                        <img
                          className="d-flex align-self-center avatar-lg img-thumbnail"
                          src={
                            imageBasePath + selectedProduct.images + imageSize
                          }
                          alt="Skote"
                          height="64"
                        />
                        <div className="media-body" style={{ margin: "5px" }}>
                          <p>Brand: {selectedProduct.brand}</p>
                          <p>Category: {selectedProduct.category}</p>
                          <p className="mb-0">
                            <code className="highlighter-rouge">
                              $ {selectedProduct.price}
                            </code>
                          </p>
                          <p>
                            Stock: {selectedProduct.inventory},{" "}
                            {selectedProduct.taxable ? "Tax" : "No Tax"}
                          </p>
                          {selectedProduct.specifications && (
                            <p className="mb-0">
                              {selectedProduct.specifications[0]}
                            </p>
                          )}
                        </div>
                      </div>
                    </Card>

                    <Card>
                      <CardBody>
                        <CardTitle>Update product</CardTitle>
                        <AvForm
                          onSubmit={this.handleSubmit}
                          className="needs-validation"
                        >
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label htmlFor="category">Name</Label>
                                <AvField
                                  name="name"
                                  placeholder="Name"
                                  type="text"
                                  errorMessage="Enter product name"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="name"
                                  value={selectedProduct.name}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label htmlFor="data">Specifications</Label>
                                <AvField
                                  name="data"
                                  placeholder="Data"
                                  type="text"
                                  errorMessage="Please provide a valid data like (200g)."
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="data"
                                  value={
                                    selectedProduct.specifications &&
                                    selectedProduct.specifications[0]
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <Label htmlFor="price">Price</Label>
                                <AvField
                                  name="price"
                                  placeholder="Price"
                                  type="number"
                                  errorMessage="Please provide a valid price."
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="price"
                                  value={selectedProduct.price}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <Label htmlFor="quantity">Quantity</Label>
                                <AvField
                                  name="quantity"
                                  placeholder="Quantity"
                                  type="number"
                                  errorMessage="Please provide a valid quantity."
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="quantity"
                                  value={selectedProduct.inventory}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <Label htmlFor="brand">Brand</Label>
                                <AvField
                                  name="brand"
                                  placeholder="Brand"
                                  type="text"
                                  errorMessage="Please enter brand"
                                  className="form-control"
                                  validate={{ required: { value: false } }}
                                  id="brand"
                                  value={selectedProduct.brand}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="4">
                              <AvField
                                type="select"
                                name="category"
                                id="category"
                                label="Category"
                                value={selectedProduct.category}
                              >
                                {this.state.categories.map((category) => (
                                  <option key={"key" + category}>
                                    {category}
                                  </option>
                                ))}
                              </AvField>
                            </Col>
                            <Col lg="4">
                              <FormGroup inline name="taxable">
                                <AvField
                                  type="checkbox"
                                  name="taxable"
                                  label="Taxable"
                                  value={selectedProduct.taxable}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <br />
                          <Button color="primary" type="submit">
                            Save changes
                          </Button>
                          &nbsp;&nbsp;
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() =>
                              this.setState({ modal_scroll: false })
                            }
                          >
                            Close
                          </button>
                        </AvForm>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <CardTitle>Image file upload</CardTitle>
                        <CardSubtitle className="mb-3">
                          Image preview available, we support only one image
                          upload
                        </CardSubtitle>
                        <Form>
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              this.handleAcceptedFiles(acceptedFiles)
                            }
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick mt-2"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {this.state.selectedFiles.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              );
                            })}
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => this.setState({ modal_scroll: false })}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.handleUpload}
                      >
                        Upload image
                      </button>
                    </div>
                  </div>
                </Modal>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default EcommerceCart;
