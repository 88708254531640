import React, { Component } from 'react';
import { Row, Col } from "reactstrap";

//Import Images
import logolight from "../../../../assets/images/logo-light.png";

class FooterLink extends Component {
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="6">
                        <div className="mb-4">
                            <img src={logolight} alt="" height="20" />
                        </div>

                        <p className="mb-2">2020 © Delivery Deals. Design & Develop by TurboDevelop</p>
                        <p>It will be as simple as accidental in fact, it will be to an english person, it will seem like simplified English, as a skeptical</p>
                    </Col>

                </Row>
            </React.Fragment>
        );
    }
}

export default FooterLink;