import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Container,
  Input,
  FormFeedback,
  FormGroup,
} from "reactstrap";
import { MenuOptionsRepeater } from "./menu-options-repeater";
import FileUpload from "./FileUpload";
import Select from "react-select";
import {
  Controller,
  useForm,
  useFieldArray,
  FormProvider,
} from "react-hook-form";
import WrappedInput from "./Input";
export const MenuItemsRepeater = ({
  rows = [],
  updateState,
  idx,
  saveMenu,
  loading,
  setModal,
  menuType,
}) => {
  const methods = useForm({
    defaultValues: {
      ...rows[idx],
    },
  });
  const { control } = methods;
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "menuOptions", // unique name for your Field Array
  });
  const [menuTypeOptions, setMenuTypeOptions] = useState([]);

  useEffect(() => {
    if (rows[idx]?.menuType !== null && rows[idx]?.menuType?.length) {
      setMenuTypeOptions(rows[idx].menuType);
    }
  }, [rows]);

  return (
    <Container>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Product</CardTitle>
              <FormProvider
                {...methods}
                fields={fields}
                append={append}
                remove={remove}
              >
                <form onSubmit={methods.handleSubmit(saveMenu)}>
                  <div className="border border-primary p-2 my-2" key={idx}>
                    <Row id={"items" + idx} className="mx-2">
                      <div data-repeater-list="group-a">
                        <Row data-repeater-item>
                          <Col lg="6" className="form-group">
                            <Label htmlFor={"name"}>Name</Label>
                            <WrappedInput
                              type="text"
                              placeholder="e.g Chicken Wings (10 Wings)"
                              {...methods.register("name")}
                            />
                          </Col>

                          <Col lg="6" className="form-group">
                            <Label htmlFor={"details"}>Description</Label>
                            <WrappedInput
                              type="details"
                              placeholder="e.g Make your own masterpiece with our huge range of crusts, toppings and sauces."
                              {...methods.register("details")}
                            />
                          </Col>
                        
                          <Col lg="2" className="form-group">
                            <Label htmlFor={"price"}>Price</Label>
                            <WrappedInput
                              type="text"
                              {...methods.register(`price`, {
                                required: true,
                              })}
                            />
                            {methods.formState?.errors["price"] && (
                              <FormFeedback>
                                This field is required
                              </FormFeedback>
                            )}
                          </Col>
                          <Col lg="2" className="form-group">
                            <Label htmlFor={"oldPrice"}>Old price</Label>
                            <WrappedInput
                              type="text"
                              {...methods.register("oldPrice", {
                                required: true,
                              })}
                            />
                          </Col>

                          <Col lg="12">
                            <Label htmlFor={"menuType"}>
                              Menu or Product Type <sup>#</sup>
                            </Label>
                            <p>
                              <sup>#</sup> <Label htmlFor={"menuType"}>If you need to add a new menu or product type, Add in the previous 'Store details' tab</Label>
                            </p>
                          </Col>
                        </Row>
                        <Row className="mx-2">

                            {menuType.map((value, idx) => (
                              <Col lg="2">
                                <WrappedInput key={idx}
                                  type="radio"
                                  value={value.value}
                                  {...methods.register("menuType")}
                                  id="menuType"
                                ></WrappedInput><Label check>{value.label}</Label>
                              </Col>
                            ))}
                        </Row>
                        <Row>
                          <Col lg="12" className="form-group" style={{ top: "10px" }}>
                            <Label for="file">Menu or Product Photo</Label>
                            <br />
                            {rows[idx].image && (
                              <img src={rows[idx].image} alt="" className="avatar-xl mr-4" />
                            )}
                            {!rows[idx].image && <Label for="file">No image found</Label>}
                          </Col>
                          <Col lg="12" className="form-group" style={{ top: "10px" }}>
                            <input
                              className="form-control-file"
                              {...methods.register("menuPhoto")}
                              type="file"
                            />
                          </Col>
                          {/* <Col lg="2" className="form-group align-self-center">
                            <Button
                              onClick={(e) => handleRemoveRowMenuItems(e, idx)}
                              color="primary"
                              className="mt-3"
                              style={{ width: "100%" }}
                            >
                              Delete
                            </Button>
                          </Col> */}
                        </Row>
                      </div>
                    </Row>
                    <MenuOptionsRepeater
                      rows={rows}
                      setState={updateState}
                      itemID={idx}
                      register={methods.register}
                      control={control}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                    disabled={loading}
                  >
                    {loading && (
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                    )}
                    Add / Update product info
                  </button>
                </form>
              </FormProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
