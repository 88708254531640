import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError } from './actions';

//Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import KeyMarvel from "../../../helpers/keymarvel";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            const response = yield call(fireBaseBackend.loginUser, user.email, user.password);
            yield put(loginSuccess(response));
        }
        else {
            const response = yield call(KeyMarvel.validateAuth, { phoneNumber: user.email, password: user.password });
            sessionStorage.setItem("authUser", JSON.stringify(response));
            sessionStorage.setItem("clientKey", response.data.id);
            sessionStorage.setItem("storeKey", response.data.storeId);
            sessionStorage.setItem("privateKey", response.data.secretKey);
            sessionStorage.setItem("userId", response.data.id);
            sessionStorage.setItem("secretKey", response.data.secretKey);
            sessionStorage.setItem("companyName", response.data.companyName);
            sessionStorage.setItem("userRole", response.data.store ? "STORE" : response.data.superAdmin ? "SUPER_ADMIN" : "ADMIN");

            let token = sessionStorage.getItem("deviceToken");
            if (token) {
                yield call(KeyMarvel.updateToken, token, response.data.id, response.data.secretKey);
            }
            yield put(loginSuccess(response));
        }
        history.push('/');
    } catch (error) {
        yield put(apiError(error));
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        sessionStorage.removeItem("authUser");
        sessionStorage.removeItem("clientKey");
        sessionStorage.removeItem("storeKey");
        sessionStorage.removeItem("privateKey");
        sessionStorage.removeItem("orderId");
        sessionStorage.removeItem("userId");
        sessionStorage.removeItem("secretKey");
        sessionStorage.removeItem("userRole");

        if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
            const response = yield call(fireBaseBackend.logout);
            yield put(logoutUserSuccess(response));
        }
        history.push('/login');
    } catch (error) {
        yield put(apiError(error));
    }
}


export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* authSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
    ]);
}

export default authSaga;