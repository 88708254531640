import React, { Component } from 'react';
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Form, FormGroup, Label, Button, Alert, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import config from "../../config.json";



//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class EditDriver extends Component {
    constructor() {
        super();
        this.state = {
            modal: false,
            categories: [],
            errorMsg: '',
            enablePassword: true,
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    enablePassword = () => {
        this.setState({
            enablePassword: !this.state.enablePassword
        });
    }

    getDriverDetails = (id) => {
        let driverKey = sessionStorage.getItem("secretKey");
        let driverId = sessionStorage.getItem("userId");
        fetch(config.DELIVERY_API + "/findDriver/" + driverId + "/" + driverKey + "/" + id)
            .then(response => response.json())
            .then(data => {
                this.setState(
                    data
                );
            });
    }


    componentDidMount() {
        const { match, location, history } = this.props;
        console.log(location)
        if (location.state && location.state.id) {
            this.getDriverDetails(location.state.id)
            this.setState({
                id: location.state.id
            })
        }
    }

    handleSubmit = (event, error, values) => {
        this.setState({ error, values });


        console.log("values", error, values);

        if (typeof error?.length !== "undefined" && error?.length === 0) {

            if (values) {
                try {
                    let driverKey = sessionStorage.getItem("secretKey");
                    let driverId = sessionStorage.getItem("userId");
                    // values.email=this.state.email;
                    this.updateDriver(values, driverId, driverKey);
                } catch (error) {
                    console.log(error);
                }
            }
        }

    }

    updateDriver = async (Object, driverId, driverKey) => {
        fetch(config.DELIVERY_API + "updateDriver/" + driverId + "/" + driverKey + "/" + this.state.id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(Object),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    this.setState({ errorMsg: data.message || data.error });
                }
                else {
                    this.setState({ errorMsg: "" });
                    this.toggle();
                    // this.form && this.form.reset();
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                this.setState({ errorMsg: error });
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Drivers" breadcrumbItem="Edit Driver" />

                        {this.state.errorMsg ? <Alert color="danger">{this.state.errorMsg}</Alert> : ''}

                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <AvForm onSubmit={this.handleSubmit} id="editDriverForm" className="needs-validation" ref={c => (this.form = c)}>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="firstName">First name*</Label>
                                                        <AvField
                                                            name="firstName"
                                                            placeholder="First name"
                                                            type="text"
                                                            errorMessage="Enter first name"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="firstName"
                                                            value={this.state.firstName}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="lastName">Last name*</Label>
                                                        <AvField
                                                            name="lastName"
                                                            placeholder="Last name"
                                                            type="text"
                                                            errorMessage="Enter last name"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="lastName"
                                                            value={this.state.lastName}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="driverLicenseNumber">Driver license number</Label>
                                                        <AvField
                                                            name="driverLicenseNumber"
                                                            placeholder="Driver license number"
                                                            type="text"
                                                            className="form-control"
                                                            validate={{ required: { value: false } }}
                                                            id="driverLicenseNumber"
                                                            value={this.state.driverLicenseNumber}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="password">Driver password ( On entered will override the password )</Label>
                                                        <AvField
                                                            name="password"
                                                            placeholder="Password"
                                                            type="text"
                                                            errorMessage="Enter password"
                                                            className="form-control"
                                                            validate={{ required: { value: false } }}
                                                            id="password" disabled={this.state.enablePassword}
                                                            value={this.state.password}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="2">
                                                    <FormGroup>
                                                        <Label htmlFor="passwordBtn">{" "}</Label>
                                                        <Button style={{marginTop: '25px'}} color="primary" type="button" onClick={this.enablePassword}>Enable password feild</Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Button color="primary" type="submit" onClick={this.handleSubmit}>Update driver</Button>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <div>
                            <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-dialog modal-dialog-centered">
                                <ModalHeader toggle={this.toggle}>Successs</ModalHeader>
                                <ModalBody>
                                    Updated driver successfully
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" tag={Link} to={{
                                        pathname: '/contacts-grid',
                                    }} onClick={this.toggle}>OK</Button>{' '}
                                    {/* <Button color="secondary" onClick={this.toggle}>Cancel</Button> */}
                                </ModalFooter>
                            </Modal>
                        </div>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default EditDriver;