import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Modal, Button, Alert } from "reactstrap";
import { MenuItemsRepeater } from "./menu-items-repeater";
import Axios from "axios";
import API from "../../config.json";
import { v4 as uuidv4 } from "uuid";
import S3 from "react-aws-s3";
import { IMAGEKIT_URL } from "../../helpers/constants";
import { generateMenuObject } from "../../helpers/utils";

export const TableRepeater = ({
  update,
  rows = [],
  updateState,
  storeId,
  storeName,
  driverId,
  driverKey,
  menuType,
  refresh,
}) => {
  const [modal, setModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [idx, setIdx] = useState(null);
  const [isAdd, setIsAdd] = useState(false);

  const handleAddRowMenuItems = () => {
    setIsAdd(true);
    const item = {
      name: "",
      menuOptions: [],
    };
    let row = JSON.parse(JSON.stringify(rows));
    row.push(item);
    updateState({
      rows: row,
    });
    setModal(true);
    setIdx(row.length - 1);
  };

  const editMenu = (key) => {
    setModal((current) => !current);
    setIsAdd(false);
    setIdx(key);
  };

  let configData = {
    bucketName: "deliverydeals72",
    region: "us-east-1",
    accessKeyId: "AKIAVWGLA2DKT7H4NGFV",
    secretAccessKey: "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",
    s3Url: "https://deliverydeals72.s3.amazonaws.com" /* optional */,
  };

  const updateStoreMenuDetails = async (Object, driverId, driverKey) => {
    let url = isAdd ? "addMenuItem/" : "updateMenuItem/";
    console.log("put, code, code", Object);
    var myObj = JSON.stringify({ Object });
    var innerObj = JSON.parse(myObj);
    console.log(innerObj);
    return await Axios.post(
      API.DELIVERY_API + url + driverId + "/" + driverKey + "/" + storeId,
      innerObj["Object"],
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  useEffect(() => {
    if (!modal && isAdd) {
      let row = JSON.parse(JSON.stringify(rows));
      row.splice(idx, 1);
      updateState({
        rows: row,
      });

      setIsAdd(false);
    }
  }, [modal, isAdd]);

  const closeModal = () => {
    setModal(false);
    setErrorMsg("");
  };

  const handleMenuItemsImages = async (menuItems, storeName) => {
    if (Object.keys(menuItems).length > 0) {
      let elementSplitName = menuItems.menuPhoto[0]?.name?.split(".");

      if (elementSplitName) {
        let elementFileName = `${storeName}-${uuidv4()}`;
        const ReactS3Client = new S3(configData);
        const resultCard = await ReactS3Client.uploadFile(
          menuItems.menuPhoto[0],
          elementFileName
        ).catch((error) => {
          console.log(error.message);
          setErrorMsg("Service error");
        });
        let tempMenuItems = JSON.parse(JSON.stringify(menuItems));
        if (resultCard) {
          console.log("uploaded details " + JSON.stringify(resultCard));
          if (resultCard && resultCard.status === 204) {
            tempMenuItems.image = IMAGEKIT_URL + resultCard.key;
            return tempMenuItems;
          }
        }
        return tempMenuItems;
      }
      return menuItems;
    }
  };

  const saveMenu = async (values) => {
    setLoading(true);
    const sendObject = await handleMenuItemsImages(values, storeName);
    // let sendObject = generateMenuObject(rows[idx]);
    const response = await updateStoreMenuDetails(
      sendObject,
      driverId,
      driverKey
    ).catch((error) => {
      console.log("code, code error", error.message);
      if (error.response) {
        console.error(error.response);
        setErrorMsg(error.response.data.message);
      } else {
        console.error(error);
        setErrorMsg(error.message);
      }
      setLoading(false);
    });

    if (response) {
      console.log(response);
      if (isAdd) {
        let temp = (rows[idx].id = response.data?.data);
        updateState({ temp });
      }
      refresh();
      setLoading(false);
      setIsAdd(false);
      closeModal();
    }
  };

  const updateMenuAvailability = async (key) => {
    let sendObject = generateMenuObject(rows[key]);
    const response = await Axios.post(
      API.DELIVERY_API +
        "updateMenuAvailability/" +
        driverId +
        "/" +
        driverKey +
        "/" +
        storeId,
      JSON.stringify({ ...sendObject }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).catch((error) => {
      console.error(error);
      setErrorMsg(error.message);
      setLoading(false);
    });
    if (response.data) {
      let row = JSON.parse(JSON.stringify(rows));

      row[key].notAvailable = !row[key].notAvailable;
      updateState({
        rows: row,
      });
    }
  };

  const deleteMenuItem = async (id, key) => {
    // let sendObject = generateMenuObject(rows[key]);
    const response = await Axios.get(
      API.DELIVERY_API +
        "removeMenuItem/" +
        driverId +
        "/" +
        driverKey +
        "/" +
        storeId +
        "/" +
        id,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).catch((error) => {
      console.error(error);
      setErrorMsg(error.message);
      setLoading(false);
    });
    if (response.data) {
      let row = JSON.parse(JSON.stringify(rows));

      row.splice(key, 1);
      updateState({
        rows: row,
      });
    }
  };

  const duplicateMenuItem = async (id, key) => {
    // let sendObject = generateMenuObject(rows[key]);
    const response = await Axios.get(
      API.DELIVERY_API +
        "duplicateMenu/" +
        driverId +
        "/" +
        driverKey +
        "/" +
        storeId +
        "/" +
        id,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).catch((error) => {
      console.error(error);
      setErrorMsg(error.message);
      setLoading(false);
    });
    if (response.data) {
      refresh();
    }
  };

  const toggle = () => {
    setModal((current) => !current);
    setErrorMsg("");
  };

  return (
    <>
      <div className="table-responsive">
        <Button
          className="add-menu"
          onClick={handleAddRowMenuItems}
          color="success"
        >
          Add Menu or Product
        </Button>
        <Table className="table table-centered table-nowrap table-sm table-hover">
          <thead className="thead-light">
            <tr>
              <th>Name</th>
              <th>Price</th>
              <th>Old price</th>
              <th>Sub category</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((item, key) => {
              return (
                <Fragment key={"_order_" + key}>
                  <tr className={item.notAvailable ? "table-danger" : ""}>
                    <td>{item.name}</td>
                    <td>{item.price}</td>
                    <td>{item.oldPrice}</td>
                    <td>
                      {item.menuType && Object.keys(item.menuType) && (
                        <>
                          {item.menuType?.value
                            ? item.menuType.value
                            : item.menuType}
                        </>
                      )}
                    </td>
                    <td>
                      <Link
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Edit menu"
                        to="#"
                        onClick={() => editMenu(key)}
                        className="text-body font-weight-bold font-size-11"
                      >
                        <i className="bx bx-edit-alt font-size-16 align-middle mr-1"></i>
                      </Link>
                      {item.notAvailable && (
                        <Link
                          color="primary"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Make menu available"
                          to="#"
                          onClick={() => updateMenuAvailability(key)}
                          className="text-body font-weight-bold font-size-11"
                        >
                          <i
                            style={{ color: "red" }}
                            className="bx bx-x-circle font-size-16 align-middle mr-1"
                          ></i>
                        </Link>
                      )}
                      {!item.notAvailable && (
                        <Link
                          color="danger"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Make menu unavailable"
                          to="#"
                          onClick={() => updateMenuAvailability(key)}
                          className="text-body font-weight-bold font-size-11"
                        >
                          <i
                            style={{ color: "green" }}
                            className="bx bx-check-circle font-size-16 align-middle mr-1"
                          ></i>
                        </Link>
                      )}
                      <Link
                        color="danger"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Duplicate menu"
                        to="#"
                        onClick={() => duplicateMenuItem(item.id, key)}
                        className="text-body font-weight-bold font-size-11"
                      >
                        <i className="bx bx-copy font-size-16 align-middle mr-1"></i>
                      </Link>
                      <Link
                        color="danger"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Delete menu"
                        to="#"
                        onClick={() => deleteMenuItem(item.id, key)}
                        className="text-body font-weight-bold font-size-11"
                      >
                        <i className="bx bx-trash font-size-16 align-middle mr-1"></i>
                      </Link>
                    </td>
                  </tr>
                </Fragment>
              );
            })}

            <Modal
              isOpen={modal}
              toggle={() => toggle()}
              scrollable={true}
              size="xl"
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0">
                  {/* {update ? "Update" : "Add"} Menu */}
                </h5>
                <button
                  type="button"
                  onClick={() => toggle()}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <MenuItemsRepeater
                  rows={rows}
                  updateState={updateState}
                  idx={idx}
                  saveMenu={saveMenu}
                  loading={loading}
                  setModal={setModal}
                  menuType={menuType}
                />
              </div>
              <div className="modal-footer">
                {errorMsg && <Alert color="danger">{errorMsg}</Alert>}
              </div>
            </Modal>
          </tbody>
        </Table>
      </div>
    </>
  );
};
