import React, { Component } from "react";

import { Row, Col, Card, CardBody, Media } from "reactstrap";
import { Link } from "react-router-dom";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import profileImg from "../../assets/images/profile-img.png";

import KeyMarvel, { imageBasePath, imageSize } from "../../helpers/keymarvel";
import config from "./../../config.json"

class WelcomeComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            firstName: null,
            lastName: null,
            profilePhoto: null,
            address: null,
            phoneNumber: null,
            errorMsg: "",
            loading: false,
            client: {}
        };
    }

    componentDidMount = async () => {
        // await this.getAccount();
        this.getDashboardDetails();
    };

    getDashboardDetails = async () => {
        let driverKey = sessionStorage.getItem("secretKey");
        let driverId = sessionStorage.getItem("userId");
        fetch(config.DELIVERY_API + "/dashboardDetails/" + driverId + "/" + driverKey + "/")
            .then(response => response.json())
            .then(data => {
                this.setState(
                    data
                );
            });
    }

    getAccount = async () => {
        const result = await KeyMarvel.getAccount();
        console.log('signup' + JSON.stringify(result));
        if (result && result.status === 200) {
            this.setState({
                email: result.data.email,
                firstName: result.data.firstName,
                lastName: result.data.lastName,
                profilePhoto: result.data.profilePhoto,
                address: result.data.address,
                phoneNumber: result.data.phoneNumber
            });
        } else {
            console.log('error' + JSON.stringify('Failed to connect'));
        }
    };

    render() {
        return (
            <React.Fragment>
                <Card className="mini-stats-wid">
                    <CardBody>
                        <Media>
                            <Media body>
                                <p className="text-muted font-weight-medium">{this.state.client.firstName} {this.state.client.lastName}</p>
                                <Link to="contacts-profile" className="btn btn-primary waves-effect waves-light btn-sm">View Profile <i className="mdi mdi-arrow-right ml-1"></i></Link>
                            </Media>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                <span className="avatar-title">
                                    <i className={"bx bx-user font-size-24"}></i>
                                </span>
                            </div>
                        </Media>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default WelcomeComp;
