import React, { Component } from "react";
import { Table, Popover, Progress, PopoverBody, Col, Card, CardBody, CardHeader, CardText } from "reactstrap";
import { withRouter, Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";
import { Fragment } from "react";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import { join } from "lodash";
import keymarvel from "../../../helpers/keymarvel";

class PopupMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      allOrders: props.allOrders,
      menu: false,
      title: "Sub orders",
      popoverbottom: false,
    };
    this.togglebottom = this.togglebottom.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  togglebottom() {
    this.setState({ popoverbottom: !this.state.popoverbottom });
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  ungroupOrder = async (orderId) => {
    console.log("Ungroup the order " + parseInt(orderId));
    console.log("Ungroup the order " + this.props.data[parseInt(orderId)]);
    await keymarvel.unGroupOrder(orderId).then((result) => {
      if (result && result.status === 200) {
        this.props.getTodaysTransactions();
      } else {
        console.error("error" + JSON.stringify("Failed to connect"));
      }
    });
  };

  render() {
    const subTaskComponents = [];
    for (let i = 0; i < this.props?.data.length; i++) {
      // looping on child
      for (let j = 0; j < this.props?.allOrders.length; j++) {
        // looping on all orders
        if (this.props?.allOrders[j].id === this.props?.data[i]) {
          subTaskComponents.push(
            <Fragment key={this.props?.allOrders[j].id}>
              <tr
                className={
                  this.props?.open
                    ? "table-success visible"
                    : "table-success not-visible"
                }
              >
                <td style={{ margin: 0, padding: 0 }}>
                  <span> - </span>
                  <Link
                      to={{
                      pathname: "/order-edit",
                      state: this.props?.allOrders[j].id,
                      }}
                      id={"edit-order" + this.props?.allOrders[j].id} title="Edit Order" className="text-body font-size-13">
                      {this.props?.allOrders[j].id}
                  </Link>
                </td>
                <td style={{ margin: 0, padding: 0 }}>
                  <span className="font-size-13 font-weight-bold">
                    {this.props?.allOrders[j].formatDate &&
                      this.props?.allOrders[j].formatDate.substring(11)}
                  </span>
                </td>
                <td style={{ margin: 0, padding: 0 }}>
                  <span className="font-size-13 font-weight-bold">
                    {this.props?.allOrders[j].storeName &&
                      this.props?.allOrders[j].storeName.substring(0, 14)}
                  </span>
                </td>
                <td style={{ margin: 0, padding: 0 }}>
                  {this.props?.allOrders[j].formatDate &&
                    this.props?.allOrders[j].formatDate.substring(12) ===
                      this.props?.allOrders[j].storeETATime && (
                      <span className="font-size-13 font-weight-bold">
                        ASAP
                      </span>
                    )}
                  {this.props?.allOrders[j].formatDate &&
                    this.props?.allOrders[j].formatDate.substring(12) !==
                      this.props?.allOrders[j].storeETATime && (
                      <span className="font-size-13 font-weight-bold">
                        {this.props?.allOrders[j].storeETATime}
                      </span>
                    )}
                </td>
                <td style={{ margin: 0, padding: 0 }}>
                  <span
                    style={{ margin: 0, padding: 0 }}
                    className="font-size-12 font-weight-bold"
                  >
                    {this.props?.allOrders[j].driverName}
                  </span>
                </td>
                <td style={{ margin: 0, padding: 0 }}>
                  <span
                    style={{ margin: 0, padding: 0 }}
                    className="font-size-12 font-weight-bold"
                  >
                    {this.props?.allOrders[j].deliveryStatus
                      ? this.props?.allOrders[j].deliveryStatus ===
                        "DELIVER_ASSIGN"
                        ? "Assigned"
                        : this.props?.allOrders[j].deliveryStatus ===
                          "DELIVER_ACCEPT"
                        ? "Accept"
                        : this.props?.allOrders[j].deliveryStatus ===
                          "DELIVER_ONTOSTORE"
                        ? "On route"
                        : this.props?.allOrders[j].deliveryStatus ===
                          "DELIVER_REACHEDSTORE"
                        ? "Arrived"
                        : this.props?.allOrders[j].deliveryStatus ===
                        "DELIVER_LEFTSTORE"
                        ? "Left store" 
                        : this.props?.allOrders[j].deliveryStatus ===
                          "DELIVER_ONTOCLIENT"
                        ? "Way to drop"
                        : this.props?.allOrders[j].deliveryStatus ===
                          "DELIVER_COMPLETED"
                        ? "Clr"
                        : "NA"
                      : "NA"}
                  </span>
                </td>
                <td style={{ margin: 0, padding: 0 }}>
                  <Progress style={{ margin: 5, padding: 0 }} multi>
                    <Progress
                      bar
                      color="primary"
                      value={
                        this.props?.allOrders[j].deliveryStatus
                          ? this.props?.allOrders[j].deliveryStatus ===
                            "DELIVER_ASSIGN"
                            ? "10"
                            : this.props?.allOrders[j].deliveryStatus ===
                              "DELIVER_ACCEPT"
                            ? "25"
                            : this.props?.allOrders[j].deliveryStatus ===
                              "DELIVER_ONTOSTORE"
                            ? "35"
                            : this.props?.allOrders[j].deliveryStatus ===
                              "DELIVER_REACHEDSTORE"
                            ? "50"
                            : this.props?.allOrders[j].deliveryStatus ===
                              "DELIVER_LEFTSTORE"
                            ? "50"
                            : this.props?.allOrders[j].deliveryStatus ===
                              "DELIVER_ONTOCLIENT"
                            ? "50"
                            : this.props?.allOrders[j].deliveryStatus ===
                              "DELIVER_COMPLETED"
                            ? "50"
                            : "0"
                          : "0"
                      }
                    ></Progress>
                    <Progress
                      bar
                      color="success"
                      value={
                        this.props?.allOrders[j].deliveryStatus
                          ? this.props?.allOrders[j].deliveryStatus ===
                            "DELIVER_ASSIGN"
                            ? "0"
                            : this.props?.allOrders[j].deliveryStatus ===
                              "DELIVER_ACCEPT"
                            ? "0"
                            : this.props?.allOrders[j].deliveryStatus ===
                              "DELIVER_ONTOSTORE"
                            ? "0"
                            : this.props?.allOrders[j].deliveryStatus ===
                              "DELIVER_REACHEDSTORE"
                            ? "0"
                            : this.props?.allOrders[j].deliveryStatus ===
                              "DELIVER_LEFTSTORE"
                            ? "15"
                            : this.props?.allOrders[j].deliveryStatus ===
                              "DELIVER_ONTOCLIENT"
                            ? "30"
                            : this.props?.allOrders[j].deliveryStatus ===
                              "DELIVER_COMPLETED"
                            ? "50"
                            : "0"
                          : "0"
                      }
                    ></Progress>
                  </Progress>
                </td>
                <td style={{ margin: 0, padding: 0 }} className="text-wrap">
                  <span className="font-size-12 font-weight-bold">
                    {this.props?.allOrders[j].deliveryAddress &&
                      this.props?.allOrders[j].deliveryAddress.addressLine1}
                  </span>
                </td>
                <td style={{ margin: 0, padding: 0 }}>
                  <span className="font-size-13 font-weight-bold">
                    {this.props?.allOrders[j].customerETATime}
                  </span>
                </td>
                <td style={{ margin: 0, padding: 0 }}>
                  <Link
                    style={{ margin: 0, padding: 0 }}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Ungroup order"
                    to="##"
                    onClick={() => {
                      this.ungroupOrder(this.props?.allOrders[j].id);
                    }}
                    className="text-body font-weight-bold font-size-16"
                  >
                    <i className="bx bx-link-external ml-1"></i>
                  </Link>
                </td>
              </tr>
            </Fragment>
          );
        }
      }
    }

    return <React.Fragment>{subTaskComponents}</React.Fragment>;
  }
}

export default withRouter(withNamespaces()(PopupMenu));