import React, { Component } from "react";
import { Table, Popover, Progress, PopoverBody, Col, Card, CardBody, CardHeader, CardText } from "reactstrap";
import { withRouter, Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";
import { Fragment } from "react";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import { join } from "lodash";
import keymarvel from "../../../helpers/keymarvel";

class PopupMenuMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      allOrders: props.allOrders,
      menu: false,
      title: "Sub orders",
      popoverbottom: false,
    };
    this.togglebottom = this.togglebottom.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  togglebottom() {
    this.setState({ popoverbottom: !this.state.popoverbottom });
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  ungroupOrder = async (orderId) => {
    console.log("Ungroup the order " + parseInt(orderId));
    console.log("Ungroup the order " + this.props.data[parseInt(orderId)]);
    await keymarvel.unGroupOrder(orderId).then((result) => {
      if (result && result.status === 200) {
        this.props.getTodaysTransactions();
      } else {
        console.error("error" + JSON.stringify("Failed to connect"));
      }
    });
  };

  render() {
    const subTaskComponents = [];
    for (let i = 0; i < this.props?.data.length; i++) {
      // looping on child
      for (let j = 0; j < this.props?.allOrders.length; j++) {
        // looping on all orders
        if (this.props?.allOrders[j].id === this.props?.data[i]) {
          subTaskComponents.push(
            <Fragment key={this.props?.allOrders[j].id}>

                <Card style={{marginLeft: "1px", marginRight: "1px", marginBottom:"2px", padding: 0, border:"1px solid" }}
                          outline>
                    <CardBody style={{margin: 0, padding: 3}}>
                        <CardHeader style={{margin: 0, padding: 0}}>
                          <Link
                              to={{
                              pathname: "/order-edit",
                              state: this.props?.allOrders[j].id,
                              }}
                              id={"edit-order" + this.props?.allOrders[j].id} title="Edit Order" className="text-body font-size-13">
                              {this.props?.allOrders[j].id}
                          </Link>
                          <Link
                            style={{ margin: 0, padding: 0 }}
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Ungroup order"
                            to="##"
                            onClick={() => {
                              this.ungroupOrder(this.props?.allOrders[j].id);
                            }}
                            className="text-body font-weight-bold font-size-16"
                          >
                            <i className="bx bx-link-external ml-1"></i>
                          </Link>
                        </CardHeader>

                        <CardText style={{margin: 0, padding: 0}}>
                          <span
                            style={{ margin: 0, padding: 0 }}
                            className="font-size-12 font-weight-bold"
                          >
                            {this.props?.allOrders[j].driverName}
                          </span>

                          <span
                              style={{margin: 0, padding: 0}}
                              className="font-size-12 font-weight-bold"
                          >{" | "}</span>

                          <span
                              style={{margin: 0, padding: 0}}
                              className="font-size-12 font-weight-bold"
                          >
                              {this.props?.allOrders[j].deliveryStatus
                                  ? this.props?.allOrders[j].deliveryStatus ===
                                      "DELIVER_ASSIGN"
                                      ? "Assigned"
                                      : this.props?.allOrders[j].deliveryStatus ===
                                          "DELIVER_ACCEPT"
                                          ? "Accept"
                                          : this.props?.allOrders[j].deliveryStatus ===
                                              "DELIVER_ONTOSTORE"
                                              ? "On route"
                                              : this.props?.allOrders[j].deliveryStatus ===
                                                  "DELIVER_REACHEDSTORE"
                                                  ? "Arrived"
                                                  : this.props?.allOrders[j].deliveryStatus ===
                                                      "DELIVER_LEFTSTORE"
                                                      ? "Left store"
                                                      : this.props?.allOrders[j].deliveryStatus ===
                                                          "DELIVER_ONTOCLIENT"
                                                          ? "Way to drop"
                                                          : this.props?.allOrders[j].deliveryStatus ===
                                                              "DELIVER_COMPLETED"
                                                              ? "Clr"
                                                              : "NA"
                                  : "NA"}
                          </span>

                          <span style={{margin: 0, padding: 0}} className="font-size-12">{" | "}{this.props?.allOrders[j].deliveryAddress && this.props?.allOrders[j].deliveryAddress.addressLine1}</span>
                          <span className="font-size-12 font-weight-bold">{" | "}{this.props?.allOrders[j].customerETATime}</span><span style={{margin: 0, padding: 0, float:"right"}} className="font-size-12 font-weight-bold">{this.props?.allOrders[j].storeName && this.props?.allOrders[j].storeName.substring(0, 14)}</span>
                        </CardText>
                    </CardBody>
                  </Card>
              </Fragment>
          );
        }
      }
    }

    return <React.Fragment>{subTaskComponents}</React.Fragment>;
  }
}

export default withRouter(withNamespaces()(PopupMenuMobile));